import {
  FETCH_VYOOS,
  FETCH_VYOOS_AVYOOERS,
  FETCH_VYOOS_RESULTS,
  VYOO_NEW,
  VYOO_LIKE,
  VYOO_FAVORITE,
  SET_CONFIRM_VOTE,
  VYOO_VOTE,
  VYOO_SHARE,
  FETCH_VYOO,
  SET_CARD_VIEW,
  LIST_VIEW_HIGHLIGHT,
  LOGOUT,
  VYOO_ADD,
  VYOO_CLEAR,
  SIGNIN,
  FETCH_SHARED_VYOO,
  CLEAR_LIST_VIEW_HIGHLIGHT,
  CHOOSE_VYOOS_REGION,
  CHOOSE_VYOOS_SORT,
  VYOO_PUT,
  FETCH_QUICK_ANSWERS,
  PUT_AVYOOER,
  VYOO_DETAILS,
  FETCH_REPORT_REASONS,
  REPORTED_VYOO,
} from '../actions';
import * as localStorage from '../localStorage';
import {
  SORT_TOP_NEW,
  LOCATION_WORLDWIDE,
  MAX_SAVED_VYOO,
} from '../../utils/constants';

const initialState = {
  vyoos: [],
  newVyoo: null,
  vyooDetails: null,
  vyoosResults: [],
  cardView: localStorage.cardView(),
  confirmVote: localStorage.confirmedVotePolicy(),
  listVyooHighlights: [],
  sharedVyoo: null,
  sortVyoos: SORT_TOP_NEW,
  regionVyoos: LOCATION_WORLDWIDE,
  regionDetailVyoos: '',
  filter: false,
  offlineVyoos: localStorage.vyoos().length !== 0 ? localStorage.vyoos() : [],
  reportedVyoos: [],
};

const removeDuplicates = (state, action) => {
  let result = [];

  if (!state.filter && state.vyoos) {
    result = [...state.vyoos];
  }
  if (action.payload) {
    result = [...result.concat(action.payload)];
  }
  state.vyoos = result ? result : [];
};

const removeDuplicatesResults = (state, action) => {
  let result = [];
  if (!state.filter && state.vyoosResults) {
    result = [...state.vyoosResults];
  }
  if (action.payload) {
    result = [...result.concat(action.payload)];
  }
  state.vyoosResults = result ? result : [];
};

const vyooReducer = (state = initialState, action) => {
  if (!action.error) {
    switch (action.type) {
      case FETCH_VYOOS:
        removeDuplicates(state, action);
        if (state.vyoos.length <= MAX_SAVED_VYOO) {
          localStorage.putVyoos(JSON.stringify(state.vyoos));
        } else {
          localStorage.putVyoos(
            JSON.stringify(
              state.vyoos.slice(
                Math.max(state.vyoos.length - MAX_SAVED_VYOO, 0)
              )
            )
          );
        }
        return {
          ...state,
          vyoos: [...state.vyoos],
          filter: false,
        };

      case FETCH_VYOOS_RESULTS:
        removeDuplicatesResults(state, action);
        return {
          ...state,
          vyoosResults: [...state.vyoosResults],
          filter: false,
        };

      case VYOO_NEW:
        return {
          ...state,
          newVyoo: action.payload,
        };

      case VYOO_ADD:
        let vyoolist = [...state.vyoos];
        vyoolist.unshift(action.payload);
        return {
          ...state,
          newVyoo: action.payload,
          vyoos: [...vyoolist],
        };

      case FETCH_VYOOS_AVYOOERS:
        action.payload.forEach((avyooer) => {
          state.vyoos.forEach((vyoo) => {
            if (vyoo.avyooer.id === avyooer.id) {
              vyoo.avyooer = avyooer;
            }
          });
          state.vyoosResults.forEach((vyoo) => {
            if (vyoo.avyooer.id === avyooer.id) {
              vyoo.avyooer = avyooer;
            }
          });
        });
        return { ...state };

      case VYOO_LIKE:
        state.vyoos = state.vyoos.map((vyoo) =>
          vyoo.id === action.payload.vyooId
            ? {
                ...vyoo,
                totalLikes: action.payload.totalLikes,
                isLiked: action.payload.isLiked,
              }
            : vyoo
        );
        state.vyoosResults = state.vyoosResults.map((vyoo) =>
          vyoo.id === action.payload.vyooId
            ? {
                ...vyoo,
                totalLikes: action.payload.totalLikes,
                isLiked: action.payload.isLiked,
              }
            : vyoo
        );
        state.sharedVyoo =
          state.sharedVyoo && state.sharedVyoo.id === action.payload.vyooId
            ? {
                ...state.sharedVyoo,
                totalLikes: action.payload.totalLikes,
                isLiked: action.payload.isLiked,
              }
            : state.sharedVyoo;
        state.vyooDetails =
          state.vyooDetails && state.vyooDetails.id === action.payload.vyooId
            ? {
                ...state.vyooDetails,
                totalLikes: action.payload.totalLikes,
                isLiked: action.payload.isLiked,
              }
            : state.vyooDetails;
        return {
          ...state,
        };

      case VYOO_FAVORITE:
        state.vyoos = state.vyoos.map((vyoo) =>
          vyoo.id === action.payload.vyooId
            ? { ...vyoo, isFavorite: action.payload.isFavorite }
            : vyoo
        );
        state.vyoosResults = state.vyoosResults.map((vyoo) =>
          vyoo.id === action.payload.vyooId
            ? { ...vyoo, isFavorite: action.payload.isFavorite }
            : vyoo
        );
        state.sharedVyoo =
          state.sharedVyoo && state.sharedVyoo.id === action.payload.vyooId
            ? {
                ...state.sharedVyoo,
                isFavorite: action.payload.isFavorite,
              }
            : state.sharedVyoo;
        state.vyooDetails =
          state.vyooDetails && state.vyooDetails.id === action.payload.vyooId
            ? {
                ...state.vyooDetails,
                isFavorite: action.payload.isFavorite,
              }
            : state.vyooDetails;
        return {
          ...state,
        };

      case VYOO_SHARE:
        state.vyoos = state.vyoos.map((vyoo) =>
          vyoo.id === action.payload.vyooId
            ? {
                ...vyoo,
                totalShares: action.payload.totalShares,
              }
            : vyoo
        );
        state.vyoosResults = state.vyoosResults.map((vyoo) =>
          vyoo.id === action.payload.vyooId
            ? {
                ...vyoo,
                totalShares: action.payload.totalShares,
              }
            : vyoo
        );
        state.sharedVyoo =
          state.sharedVyoo && state.sharedVyoo.id === action.payload.vyooId
            ? {
                ...state.sharedVyoo,
                totalShares: action.payload.totalShares,
              }
            : state.sharedVyoo;
        state.vyooDetails =
          state.vyooDetails && state.vyooDetails.id === action.payload.vyooId
            ? {
                ...state.vyooDetails,
                totalShares: action.payload.totalShares,
              }
            : state.vyooDetails;
        return {
          ...state,
        };

      case FETCH_SHARED_VYOO:
        return {
          ...state,
          sharedVyoo: { ...action.payload },
        };

      case SET_CONFIRM_VOTE: {
        localStorage.putConfirmedVotePolicy(action.confirmVote);
        return {
          ...state,
          confirmVote: action.confirmVote,
        };
      }

      case VYOO_VOTE: {
        state.vyoos = state.vyoos.map((vyoo) =>
          vyoo.id === action.payload.vyooId
            ? {
                ...vyoo,
                isVoted: true,
                totalVotes: action.payload.totalVotes,
                answers: action.payload.answers,
                answer: action.payload.answer,
              }
            : vyoo
        );
        state.sharedVyoo =
          state.sharedVyoo && state.sharedVyoo.id === action.payload.vyooId
            ? {
                ...state.sharedVyoo,
                isVoted: true,
                totalVotes: action.payload.totalVotes,
                answers: action.payload.answers,
                answer: action.payload.answer,
              }
            : state.sharedVyoo;

        state.vyooDetails =
          state.vyooDetails && state.vyooDetails.id === action.payload.vyooId
            ? {
                ...state.vyooDetails,
                isVoted: true,
                totalVotes: action.payload.totalVotes,
                answers: action.payload.answers,
                answer: action.payload.answer,
              }
            : state.vyooDetails;
        return {
          ...state,
        };
      }

      case FETCH_VYOO:
        state.vyoos = state.vyoos.map((vyoo) =>
          vyoo.id === action.payload.id ? action.payload : vyoo
        );
        return {
          ...state,
        };

      case VYOO_DETAILS:
        return {
          ...state,
          vyooDetails: action.vyoo,
        };

      case SET_CARD_VIEW: {
        localStorage.putCardView(action.cardView);
        return {
          ...state,
          cardView: action.cardView,
        };
      }

      case LIST_VIEW_HIGHLIGHT: {
        return {
          ...state,
          listVyooHighlights: [...state.listVyooHighlights, action.vyooId],
        };
      }

      case CLEAR_LIST_VIEW_HIGHLIGHT:
        return {
          ...state,
          listVyooHighlights: [],
        };

      case CHOOSE_VYOOS_SORT:
        return {
          ...state,
          sortVyoos: action.sortVyoos,
          filter: true,
        };

      case VYOO_CLEAR:
        return {
          ...state,
          newVyoo: null,
        };

      case CHOOSE_VYOOS_REGION:
        return {
          ...state,
          regionVyoos: action.regionVyoos,
          regionDetailVyoos: action.regionDetailVyoos,
          filter: true,
        };

      case VYOO_PUT:
        return {
          ...state,
          newVyoo: action.payload,
        };

      case PUT_AVYOOER:
        action.payload.avatar &&
          state.vyoos.forEach((vyoo) => {
            if (vyoo.avyooer.id === action.payload.id) {
              vyoo.avyooer.avatar = action.payload.avatar;
            }
          });
        return {
          ...state,
        };

      case FETCH_QUICK_ANSWERS:
        return {
          ...state,
          quickAnswers: action.payload,
        };

      case FETCH_REPORT_REASONS:
        return {
          ...state,
          reportReasons: action.payload,
        };

      case REPORTED_VYOO:
        return {
          ...state,
          reportedVyoos: [...state.reportedVyoos, action.vyooId],
        };

      case LOGOUT:
      case SIGNIN:
        return initialState;

      default:
        return { ...state };
    }
  } else {
    return state;
  }
};
export default vyooReducer;
