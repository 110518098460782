import {
  FETCH_CATEGORIES,
  FETCH_COUNTRIES,
  FETCH_REGIONS,
  FETCH_MARITAL,
  FETCH_EDUCATION,
  FETCH_PROFESSION,
  FETCH_NATIONALITY,
} from '../actions';

const initialState = {
  countries: [],
  regions: [],
  categories: [],
  maritalStatus: [],
  educations: [],
  professions: [],
  nationalities: [],
};

const refReducer = (state = initialState, action) => {
  if (!action.error) {
    switch (action.type) {
      case FETCH_COUNTRIES:
        return {
          ...state,
          countries: action.payload,
        };

      case FETCH_REGIONS:
        return {
          ...state,
          regions: action.payload,
        };

      case FETCH_CATEGORIES:
        return {
          ...state,
          categories: action.payload,
        };

      case FETCH_MARITAL:
        return {
          ...state,
          maritalStatus: action.payload,
        };

      case FETCH_EDUCATION:
        return {
          ...state,
          educations: action.payload,
        };

      case FETCH_PROFESSION:
        return {
          ...state,
          professions: action.payload,
        };
      case FETCH_NATIONALITY:
        return {
          ...state,
          nationalities: action.payload,
        };

      default:
        return state;
    }
  } else {
    return state;
  }
};
export default refReducer;
