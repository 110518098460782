export default `
مرحبًا بك في أفيو ، أفضل مجتمع حيث يمكنك طرح أي سؤال حول أي موضوع ، والتصويت على أي شيء في أي وقت ، ومشاركة وجهات نظرك مع أصدقائك والعالم بأسره. تحكم هذه الشروط التالية وصولك إلى خدمات أفيو الخاصة بنا واستخدامها ، من خلال تطبيق الهاتف المحمول والموقع الإلكتروني.
<br /> <br />
إذا واصلت استخدام نظام أفيو الأساسي ، فهذا يعني أنك توافق على الالتزام ببنود وشروط الاستخدام التالية والالتزام بها ، والتي تحكم مع سياسة الخصوصية الخاصة بنا علاقة أفيو معك.
<br /> <br />
<b>استخدام منصة أفيو</b> 
<br /> <br />
الأهلية: يمكن لأي شخص فوق 13 سنة استخدام منصة أفيو . ومع ذلك ، بالنسبة لأولئك الذين تقل أعمارهم عن هذا العمر ، يجب أن يوافق آباؤهم أو الأوصياء القانونيون على شروط الاستخدام هذه ويؤكدوا أنهم يقبلون هذه الاتفاقية نيابة عنهم ويتحملون مسؤولية استخدامها. إذا كنت توافق على هذه الشروط وتستخدم الخدمات نيابة عن شركة أو مؤسسة أو حكومة أو كيان قانوني آخر ، فإنك تقر وتضمن أنك مفوض للقيام بذلك.
<br /> <br />
التسجيل: عند إنشاء حسابك على منصة أفيو ، سيُطلب منك تقديم معلومات معينة عنك. أنت توافق على تزويدنا بمعلومات دقيقة ، بما في ذلك اسمك الحقيقي. سنتعامل مع معلوماتك وفقًا لسياسة الخصوصية الخاصة بنا. يجب أن تحرص على الحفاظ على سرية كلمة المرور الخاصة بك.
<br /> <br />
سياسة الخصوصية: تم توضيح ممارسات الخصوصية الخاصة بنا في سياسة الخصوصية الخاصة بنا. باستخدام منصة أفيو ، فإنك توافق على قبول سياسة الخصوصية الخاصة بنا ، بغض النظر عما إذا كنت مستخدمًا مسجلاً أم لا.
<br /> <br />
الإنهاء: يمكنك إغلاق حسابك في أي وقت بالانتقال إلى إعدادات الحساب وتعطيل حسابك. يجوز لنا إنهاء أو تعليق حسابك في أفيو إذا انتهكت أي سياسة أفيو أو لأي سبب آخر.
<br /> <br />
التحديثات: نحاول دائمًا تحسين تجربتك على منصة أفيو . قد نحتاج إلى إضافة ميزات أو تغييرها وقد نقوم بذلك دون إشعارك.
<br /> <br />
ملاحظات: نرحب بتعليقاتكم واقتراحاتكم حول كيفية تحسين منصة أفيو . لا تتردد في إرسال التعليقات على admin@avyoo.com. من خلال إرسال التعليقات ، فإنك توافق على منحنا الحق ، وفقًا لتقديرنا ، في استخدام التعليقات والكشف عنها واستغلالها ، كليًا أو جزئيًا ، بحرية وبدون تعويض لك.
<br /> <br />
<b>إدارة المحتوى الخاص بك</b>
<br /> <br />
المحتوى الخاص بك: تتيح لك منصة أفيو إنشاء استطلاعات الرأي وإضافة المنشورات والنصوص والصور ومقاطع الفيديو والروابط والملفات لمشاركتها مع الآخرين. سيشار إلى جميع المواد التي تقوم بتحميلها أو نشرها أو عرضها للآخرين عبر منصة أفيو بشكل جماعي باسم "المحتوى الخاص بك". أنت تقر وتوافق على أنه يمكن عرض المحتوى الخاص بك من قبل عامة الناس.
<br /> <br />
مسؤولياتك: من خلال نشر المحتوى الخاص بك على منصة أفيو ، فإنك تقر وتضمن لنا ما يلي:
<br /> <br />
<li>
     لديك حقوق الملكية ، أو أنك حصلت على جميع التراخيص أو الأذونات اللازمة من أي أطراف ضرورية ، لاستخدام المحتوى الخاص بك ومنحنا حقوق استخدام المحتوى الخاص بك على النحو المنصوص عليه في هذه الاتفاقية ، و
</li>
<li> 
    لا ينتهك المحتوى الخاص بك أي ملكية فكرية أو حقوق شخصية للآخرين أو أي قانون أو لائحة معمول بها.
</li>
<br /> <br />
وبالتالي ، فإنك تتحمل المسؤولية الكاملة عن تجنب انتهاك حقوق الملكية الفكرية أو الحقوق الشخصية للآخرين أو انتهاك القوانين واللوائح المتعلقة بالمحتوى الخاص بك. أنت مسؤول أيضًا عن التأكد من أن المحتوى الخاص بك لا ينتهك سياسات أفيو أو أي قانون أو لائحة معمول بها.
<br /> <br />
<b>إدارة المحتوى الخاص بنا</b>
<br /> <br />
تحتوي منصة أفيو على بيانات ومواد مملوكة لنا أو مرخصة لنا. تتضمن هذه المواد ، على سبيل المثال لا الحصر ، التصميم والتخطيط والشكل والمظهر والرسومات. يحظر الاستنساخ إلا بموجب إشعار حقوق الطبع والنشر ، الذي يشكل جزءًا من شروط الاستخدام هذه.
<br /> <br />
جميع العلامات التجارية التي تمت إعادة إنتاجها في أفيو والتي ليست مملوكة أو مرخصة للمشغل معترف بها على منصتنا. قد يؤدي الاستخدام غير المصرح به إلى المطالبة بالتعويض و / أو أن يكون جريمة جنائية.
<br /> <br />
قد يتضمن أفيو أيضًا روابط جديدة إلى مواقع ويب أخرى ، من وقت لآخر. يتم توفير هذه الروابط لراحتك لتقديم مزيد من المعلومات. ليس لدينا سيطرة على طبيعة ومحتوى وتوافر هذه المواقع. إدراج أي روابط لا يعني بالضرورة توصية أو يؤيد الآراء المعبر عنها داخلها.
<br /> <br />
يتم بذل كل جهد ممكن لتحسين أداء ووظائف أفيو . ومع ذلك ، فإن أفيو لا تتحمل أي مسؤولية ولن تكون مسؤولة عن عدم توفر النظام الأساسي مؤقتًا بسبب مشكلات فنية خارجة عن سيطرتنا.
<br /> <br />
تسري هذه الاتفاقية اعتبارًا من 11 مايو 2020.

`;