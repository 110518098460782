import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import Modal from '../modal/Modal';
import withPageUtils from '../../utils/hoc/withPageUtils';
import { useState } from 'react';
import palette from '../../theme/palette';
import { ReactComponent as Done } from '../../assets/svg/done.svg';
import * as localStorage from '../../store/localStorage';
import { theme } from '../../theme';
import { useTranslation } from 'react-i18next';

const ModalLanguage = ({ t, open, onClose, apply }) => {
  const { i18n } = useTranslation();

  const [lng, setLng] = useState(localStorage.language() || 'en');

  const setLanguage = () => {
    i18n.changeLanguage(lng).then(() => apply());
    document.body.dir = i18n.dir();
    theme.direction = i18n.dir();
    setLng(lng);
  };

  return (
    <Modal
      title={t('settings.language')}
      open={!!open}
      onClose={() => onClose()}
      apply={() => setLanguage()}
    >
      <List>
        {localStorage.languageList().map((language, i) => {
          return (
            <ListItem
              key={i}
              button
              onClick={() => setLng(language.code)}
              style={{
                background:
                  lng === language.code ? palette.background.default : 'white',
              }}
            >
              <ListItemText>{language.name}</ListItemText>
              {lng === language.code && (
                <Done
                  style={{ fill: palette.primary.dark }}
                  className="done-icon"
                />
              )}
            </ListItem>
          );
        })}
      </List>
    </Modal>
  );
};

export default withPageUtils(ModalLanguage);
