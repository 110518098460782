export const VYOO_STATUS_ACTIVE = 'A';
export const VYOO_STATUS_EXPIRED = 'E';
export const VYOO_STATUS_INACTIVE = 'D';

export const VYOO_FULLY_EDITABLE = 'F';
export const VYOO_PARTIALLY_EDITABLE = 'P';

export const VISIBILITTY_PUBLIC = 'PU';
export const VISIBILITTY_PRIVATE = 'PV';

export const LOCATION_WORLDWIDE = 'WW';
export const LOCATION_COUNTRY = 'CO';
export const LOCATION_REGION = 'RG';

export const SORT_TOP_NEW = 'DT';
export const SORT_TOP_LIKE = 'ML';
export const SORT_TOP_SHARE = 'MS';
export const SORT_TOP_VOTE = 'MV';

export const SHARE_FACEBOOK = 'FB';
export const SHARE_TWITTER = 'TW';
export const SHARE_LINKEDIN = 'LI';
export const SHARE_WHATSAPP = 'WA';
export const SHARE_EMAIL = 'EM';

export const URL_NEW_PARAM = 'new';

export const MAX_QUESTION_CHARACTERS = 300;
export const MAX_ANSWER_CHARACTERS = 150;
export const MIN_ANSWER_COUNT = 2;
export const MAX_ANSWER_COUNT = 10;
export const MAX_CATEGORIES = 3;

export const MAX_FILE_SIZE = 500000;

export const arabic = /[\u0600-\u06FF]/;

export const MAX_SAVED_VYOO = 10;

export const routes = [
  '/activities',
  '/setting',
  '/setting/Profile',
  '/setting/editPassword',
];
