import * as actions from '../actions';
import * as localStorage from '../localStorage';

const authMiddleware = (store) => (next) => (action) => {
  if (!action.error) {
    switch (action.type) {
      case actions.SIGNUP:
      case actions.SIGNIN:
        localStorage.removeGuestAvyooerId();
        localStorage.removeGuestAvyooerName();
        localStorage.removetotalVyoosGuest();
        localStorage.putToken(action.payload.token);
        localStorage.putAccessToken(action.payload.access);
        localStorage.putRefreshToken(action.payload.refresh);
        localStorage.putAvyooer(action.payload.avyooer);
        if (action.type === actions.SIGNIN) {
          //store.dispatch(actions.getAvyooerByID());
          store.dispatch(actions.fetchVyoos());
        }
        break;

      case actions.LOGOUT:
        localStorage.removeToken();
        localStorage.removeAccessToken();
        localStorage.removeRefreshToken();
        localStorage.removeAvyooer();
        localStorage.guestAvyooerId();
        break;

      default:
    }
  }
  next(action);
};

export default authMiddleware;
