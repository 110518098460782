export default `
Welcome to AVYOO, the best community where you can ask any question about any topic, Vote on anything any time, and share your views with your friends and the whole world. These following Terms govern your access to and use of our AVYOO services, through the mobile application and the website.
<br /> <br />
If you continue to use AVYOO platform, this means that you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern AVYOO's relationship with you.
<br /> <br />
<b>Using the AVOO Platform</b>
<br /> <br />
Eligibility: Anyone above 13 years of age can use the AVYOO Platform. However, for those under this age, their parents or legal guardians must consent to these Terms of use and affirm that they accept this Agreement on their behalf and bear responsibility for their use.  If you are accepting these Terms and using the Services on behalf of a company, organization, government, or other legal entity, you represent and warrant that you are authorized to do so. 
<br /> <br />
Registration: When you create your account on the AVYOO Platform, you will be asked to provide certain information about yourself. You agree to provide us accurate information, including your real name. We will treat your information following our Privacy Policy. You should take care in maintaining the confidentiality of your password.
<br /> <br />
Privacy Policy: Our privacy practices are outlined in our Privacy Policy. By use of the AVYOO Platform, you agree to accept our Privacy Policy, regardless of whether you are a registered user.
<br /> <br />
Termination: You may close your account at any time by going to account settings and disabling your account. We may terminate or suspend your AVYOO account if you violate any AVYOO policy or for any other reason.
<br /> <br />
Updates: We are always trying to improve your experience on the AVYOO Platform. We may need to add or change features and may do so without notice to you.
<br /> <br />
Feedback: We welcome your feedback and suggestions about how to improve the AVYOO Platform. Feel free to submit feedback at admin@avyoo.com. By submitting feedback, you agree to grant us the right, at our discretion, to use, disclose and otherwise exploit the feedback, in whole or part, freely and without compensation to you.
<br /> <br />
<b>Managing your Content</b>
<br /> <br />
Your content: The AVYOO Platform allows you to create polls and add posts, texts, photos, videos, links, and files to share with others. All material that you upload, publish or display to others via the AVYOO Platform will be referred to collectively as “Your Content.” You acknowledge and agree that Your Content may be viewed by the general public.
<br /> <br />
Your Responsibilities: By posting Your Content on the AVYOO Platform, you represent and warrant to us that:
<br /> <br />
<li>
    	You have the ownership rights, or you have obtained all needed licenses or permissions from any necessary parties, to use Your Content and grant us the rights to use Your Content as provided for under this Agreement, and
</li>	
<li>
    Your Content violates no intellectual property or personal rights of others or any applicable law or regulation.
</li>
<br /> <br />
Thus, you accept full responsibility for avoiding violation of the intellectual property or personal rights of others or infringement of laws and regulations in connection with Your Content. You are also responsible for ensuring that Your Content does not violate AVYOO’s policies, or any applicable law or regulation.
<br /> <br />
<b>Managing Our Content</b>
<br /> <br />
The AVYOO platform contains data and material which are owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than under the copyright notice, which forms part of these terms of use.
<br /> <br />
All trademarks reproduced in AVYOO which are not the property of, or licensed to, the operator are acknowledged on our platform. Unauthorized use may give rise to a claim for damages and/or be a criminal offense.
<br /> <br />
AVYOO may also include new links to other websites, from time to time. These links are provided for your convenience to provide further information. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
<br /> <br />
Every effort is made to improve the performance and the functionalities of AVYOO. However, AVYOO takes no responsibility for, and will not be liable for, the platform being temporarily unavailable due to technical issues beyond our control.
<br /> <br />
This agreement is effective from May 11, 2020.

`;