import { SORT_TOP_NEW } from '../../utils/constants';
import * as actions from '../actions';
import * as localStorage from '../localStorage';

const vyooMiddleware = (store) => (next) => (action) => {
  let state = store.getState();
  if (!action.error) {
    switch (action.type) {
      case actions.FETCH_VYOOS:
      case actions.FETCH_VYOOS_RESULTS:
        if (action.payload.length > 0) {
          store.dispatch(actions.fetchVyoosAvyooers(action.payload));
        }
        break;

      case actions.VYOO_ADD:
        if (
          localStorage.guestAvyooerId() &&
          localStorage.totalVyoosGuest() > 0
        ) {
          store.dispatch(
            actions.settotalVyoosGuest(localStorage.totalVyoosGuest())
          );
        }
        break;

      case actions.FETCH_SHARED_VYOO:
        if (action.payload) {
          store.dispatch(
            actions.fetchVyoos(
              SORT_TOP_NEW,
              action.payload.region,
              action.payload.regionDetail,
              null,
              action.payload.categories
            )
          );
        }
        break;

      case actions.VYOO_PUT:
        state.avyooer.avyooerVyoos = state.avyooer.avyooerVyoos.map((vyoo) =>
          vyoo.id === action.payload.id
            ? {
                ...action.payload,
                isFavorite: vyoo.isFavorite,
                isLiked: vyoo.isLiked,
                isVoted: vyoo.isVoted,
                avyooer: { ...vyoo.avyooer },
              }
            : vyoo
        );
        state.vyoo.vyoos = state.vyoo.vyoos.map((vyoo) =>
          vyoo.id === action.payload.id
            ? {
                isFavorite: vyoo.isFavorite,
                isLiked: vyoo.isLiked,
                isVoted: vyoo.isVoted,
                ...action.payload,
                avyooer: { ...vyoo.avyooer },
              }
            : vyoo
        );
        break;

      case actions.VYOO_DELETE:
        state.avyooer.avyooerVyoos = state.avyooer.avyooerVyoos.filter(
          (item) => item.id != action.payload.vyooId
        );
        state.vyoo.vyoos = state.vyoo.vyoos.filter(
          (item) => item.id != action.payload.vyooId
        );
        break;

      case actions.VYOO_TURN_OFF:
        state.avyooer.avyooerVyoos = state.avyooer.avyooerVyoos.map((vyoo) =>
          vyoo.id === action.payload.id
            ? { ...vyoo, status: action.payload.status }
            : vyoo
        );
        state.vyoo.vyoos = state.vyoo.vyoos.filter(
          (item) => item.id != action.payload.id
        );
        break;

      case actions.VYOO_REPORT:
        if (action.payload) {
          store.dispatch(actions.ReportedVyoo(action.payload.vyooId));
          state.vyoo.vyoos = state.vyoo.vyoos.filter(
            (item) => item.id !== action.payload.vyooId
          );
        }
        break;

      default:
    }
  }
  next(action);
};

export default vyooMiddleware;
