import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import store from './store';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { fetchCountries, fetchRegions, fetchVyoos } from './store/actions';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import i18n from './i18n';

setTimeout(() => {
  store.dispatch(fetchVyoos());
  store.dispatch(fetchCountries());
  store.dispatch(fetchRegions());
}, 0);

ReactDOM.render(
  <Provider store={store}>
    <CssBaseline />
    <Suspense fallback={<div className="vertical-centering">Loading ...</div>}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
