export default `
    This privacy policy explains how AVYOO uses and protects any information that you provide while using the App or the website.
    <br /> <br />
    AVYOO considers your privacy as very important and takes it seriously. This Privacy Policy describes our policies and procedures on the collection, use, disclosure, and sharing of your personal information or personal data when you use the AVYOO Platform. We will maintain extreme confidentiality about this information. It will be used or shared only in the way as described in this Privacy Policy. By using AVYOO services you consent to the collection, transfer, storage, disclosure, and other uses of your personal information as described in this Privacy Policy.
    <br /> <br />
    AVYOO may change this policy from time to time by updating this page. You will be notified to ensure that you are happy with any changes. By continuing to use AVYOO without consenting to the changes or opting out, it will be deemed that you have consented to the new changes. This policy is effective from May 11, 2020.
    <br /> <br />
    In this privacy policy, the term “us” or “we” refers to the owner of the AVYOO platform, while the term “you” or “yours” refers to the user or viewer of AVYOO.
    <br /> <br />
    <b>Why your information is collected?</b>
    The information collected by AVYOO is utilized mainly to personalize the content to serve you well, by presenting to you the polls that match your profile and your interests. The retained information is needed to ease efficient access to your account, oversee collective metrics such as the total number of visitors, traffic, and demographic patterns, and track user Content and users. Also, these data are necessary to evaluate how the Service is used, detect service or technical problems, and uphold security.
    <br /> <br />
    <b> Which kind of information do we collect? </b>
    When you register for an account with AVYOO, we collect necessary information about yourself, such as your name, email, marital status, occupation, date of birth, gender, location and profile picture, as well as your preferred categories of polls’ topics. Once your profile is created, your username and limited basic information of your profile content are available publicly. AVYOO registered users will be able to see certain information on your profile page, such as the polls you post, your followers and who you follow, unless if you make some of this information private.
    <br /> <br />
    We also collect your basic profile information when you log in on AVYOO via social media sign in, such as Facebook or Google, or LinkedIn. These Linked Networks may also appear in your profile. The specific information provided to us is determined by these third parties and may vary by network.
    <br /> <br />
    We collect and store the information and content that you post to the AVYOO Platform, including your polls, votes, likes, comments, and basic profile information. Your content (except your votes), date and time stamps, are publicly viewable on the AVYOO Platform, along with your name.
    <br /> <br />
    In addition, we use basic tracking technologies (cookies, …) to automatically collect information about your activities, such as your searches, page views, date and time of your visit, and other information about your browsing information, such as IP address and location, URL, unique advertising or content identifiers (if applicable) and time zone, and other information about user activities on the AVYOO Platform.
    <br /> <br />
    In case you choose to invite a friend via AVYOO, you will be asked to provide that person’s email address. A one-time email invitation will be sent to your friend automatically. AVYOO retains this information to send invitations, to enroll your friend if your invitation is accepted and to keep a track of the success of our invitation service.
    <br /> <br />
    When you communicate with us (via email, through the AVYOO Platform or otherwise), we may maintain a record of your communication. By disclosing your contact information to AVYOO, you consent to our use of your contact information to communicate with you.
    <br /> <br />
    <b>How long AVYOO will keep your personal information?</b>
    <br /> <br />
    Your personal information will be kept indefinitely until you delete your account. You can delete anytime your account and all associated data. To do so, please visit your AVYOO settings page.
    <br /> <br />
    <b>How we use your information?</b>
    <br /> <br />
    We do not sell your personal data, such as your name and contact information, to third parties to use for their own marketing purposes. AVYOO uses the information we collect for the following purposes:
    <li>	
        To tailor the content and information according to your needs, in order to personalize your experiences while using the AVYOO Platform. Your data will allow us to suggest to you Polls, followers and content, as well as personalized help and instructions.
    </li>	
    <li>
    To prepare aggregate statistics, metrics and other reports about the performance of and the results of the voted polls, taking into consideration some characteristics of the users who participated in their polls, such as their age, gender, occupation and date and time information, without mentioning any individual details.
    </li>
    <li>
            To gather metrics to better understand how users access and use the AVYOO Platform; 
    </li>
    <li>
            To evaluate and improve the AVYOO Platform, including the Ad Services and personalization, and to develop new products and services.
    </li>
    <li>
            To communicate with you (via e-mail or App notifications), about your use of the AVYOO Platform, respond to your inquiries, and for other customer service purposes. Also, to send you news and newsletters, special offers, and promotions, or to otherwise contact you about products or information we think may interest you, including information about third party products and services. You will have the opportunity to unsubscribe from receiving such periodic emails from us or deactivate these notifications.
    </li>
    <li>
            To comply with legal obligations, as part of our general business operations, and for other business administration purposes.
    </li>
    <li>	
        To prevent any misuse and take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person, or violations of our Terms of Service or this Privacy Policy.
    </li>
    <br /> <br />
    <b>How we share your information? </b>
    <br /> <br />
    Your content, including your name, profile picture, mini-bio, and certain associated activity information may be viewed by other AVYOO registered users. Non-registered visitors are not able to see your profile, but they can only see and vote on your polls.
    <br /> <br />
    We may share publicly the aggregate statistics, metrics and other reports about the results of the voted polls or content in the AVYOO Platform. We do not share IP addresses or personal information like your name. 
    <br /> <br />
    We may share aggregated information with third parties for research, marketing, analytics and other purposes, without identifying a particular individual.
    <br /> <br />
    We may share information about your activity with third-party service providers who use this information to perform services for us, such as payment processors, hosting providers, auditors, advisors, consultants, customer service and support providers. But in doing so, we will not compromise your personal information.
    <br /> <br />
    We may disclose your information if we are required to do so by law, and when we believe it necessary to respond to claims asserted against us or, comply with legal process, enforce or administer our agreements and terms, for fraud prevention, risk assessment, investigation, and protect the rights, property or safety of AVYOO, its users, or others.
    <br /> <br />
    <b>How we protect your information?</b>
    <br /> <br />
    The security of your information is very important to us. AVYOO has implemented safeguards to protect the information we collect. However, no website or Internet transmission is completely secure. We urge you to take steps to keep your personal information safe, such as choosing a strong password and keeping it private, as well as logging out of your user account, and closing your web browser when finished using the AVYOO Platform on a shared or unsecured device.
    <br /> <br />
    <b>How you manage your information?</b>
    You may update or correct your account information at any time by logging in to your account. You may adjust your privacy settings to control the display of your information. You may also manage the types of notifications and communications we send, limit the information shared within the AVYOO about you, and otherwise amend certain privacy settings.
    <br /> <br />
    As per your Content, you may edit and delete the polls that you create at any time, as long as they have no votes. Beyond that, the information may prove to be useful for research or other meaningful purposes and the poll will automatically become the property of the AVYOO.
    <br /> <br />
    You choose to delete your account anytime, but then all of your polls will be removed from public visibility on the AYOO, and it may not be restored by us, even if you change your mind. And you can also choose to deactivate your account, then you will no longer receive any communications from us, and users will not be able to interact with you; however your content will remain on the AVYOO, and you can reactivate it any time by choosing to log in.
    <br /> <br />
    Adult content might automatically be removed, as well as any poll if it is reported for abuse by many users and might prove to be too sensitive for the general user. We aim to make AVYOO a safe and easy platform for everybody.
    <br /> <br />
    <b>What about links to Other Websites?</b>
    <br /> <br />
    The AVYOO Platform may contain links to third-party sites or online services or media content from other websites. We are not responsible for the practices of such third parties, whose information practices are subject to their own policies and procedures, not to this Privacy Policy.
    <br /> <br />
    <b>How do you contact Us?</b>
    <br /> <br />
    If you have any questions or grievances about our practices or this Privacy Policy, please send us an email to admin@avyoo.com
`;