export const LOADING = 'LOADING';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const errors = {};

export const loading = (action) => {
  return {
    type: LOADING,
    payload: { action },
  };
};
