import aboutus_en from './aboutus_en.html';
import aboutus_fr from './aboutus_fr.html';
import aboutus_ar from './aboutus_ar.html';
import terms_of_use_en from './terms_of_use_en.html';
import terms_of_use_fr from './terms_of_use_fr.html';
import terms_of_use_ar from './terms_of_use_ar.html';
import privacy_policy_en from './privacy_policy_en.html';
import privacy_policy_fr from './privacy_policy_fr.html';
import privacy_policy_ar from './privacy_policy_ar.html';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  aboutus: {
    en: aboutus_en,
    fr: aboutus_fr,
    ar: aboutus_ar,
  },
  privacyPolicy: {
    en: privacy_policy_en,
    fr: privacy_policy_fr,
    ar: privacy_policy_ar,
  },
  termsOfUse: {
    en: terms_of_use_en,
    fr: terms_of_use_fr,
    ar: terms_of_use_ar,
  },
};
