import base64 from 'base-64';
import jwtDecode from 'jwt-decode';
import * as localStorage from './localStorage';
import { loading } from './actions';
import { UPDATE_AVYOOER_PASSWORD } from './actions';

const BASE_URL = 'https://api.avyoo.com';
export const REF_URL = BASE_URL + '/ref/v0/';
export const AUTH = BASE_URL + '/usr/v0/auth';
export const AVYOOER_ACTIVITIES_FAVORITES_URL =
  BASE_URL + '/vy/v0/avyooer/vyoos/favorites?limit=4';
export const AVYOOER_ACTIVITIES_VOTES_URL =
  BASE_URL + '/vy/v0/avyooer/vyoos/votes?limit=4';
export const AVYOOER_ACTIVITIES_VYOOS_URL =
  BASE_URL + '/vy/v0/avyooer/vyoos?limit=4';
export const AVYOOER_LIST_URL = BASE_URL + '/av/v0/avyooer/comment';
export const AVYOOER_MORE_INFO_URL = BASE_URL + '/av/v0/avyooer/moreInfo';
export const AVYOOER_URL = BASE_URL + '/av/v0/avyooer/';
export const CATEGORIES_URL = BASE_URL + '/av/v0/avyooer/categories';
export const GET_ActionsAllTime_URL =
  BASE_URL + '/vy/v0/stats/avyooer/totalactions';
export const GET_CHILD_COMMENTS_URL =
  BASE_URL + '/cmt/v0/vyoo/{vyooId}/comment/{commentId}/comments';
export const GET_COMMENTS_URL = BASE_URL + '/cmt/v0/vyoo/{vyooId}/comment/';
export const GET_followersCountriesStats_URL =
  BASE_URL + '/av/v0/stats/avyooer/followers/countries/{step}/{stepDate}';
export const GET_followingsCountriesStats_URL =
  BASE_URL + '/av/v0/stats/avyooer/followings/countries/{step}/{stepDate}';
export const GET_GeneralTotalActionsDaily_URL =
  BASE_URL + '/vy/v0/stats/avyooer/{step}/{stepDate}';
export const GET_GeneralTotalCommentsDaily_URL =
  BASE_URL + '/cmt/v0/stats/avyooer/totalcomments/{step}/{stepDate}';
export const GET_GeneralTotalFollowersDaily_URL =
  BASE_URL + '/av/v0/stats/avyooer/totalfollowers/{step}/{stepDate}';
export const GET_GeneralTotalVyoosDaily_URL =
  BASE_URL + '/vy/v0/stats/avyooer/vyoos/{step}/{stepDate}';
export const GET_TotalCommentsStats_URL =
  BASE_URL + '/cmt/v0/stats/avyooer/{step}/{startDate}/{numberStep}';
export const GET_TotalFollowersStats_URL =
  BASE_URL + '/av/v0/stats/avyooer/followers/{step}/{startDate}/{numberStep}';
export const GET_TotalFollowingsStats_URL =
  BASE_URL + '/av/v0/stats/avyooer/followings/{step}/{startDate}/{numberStep}';
export const GET_TotalLikesStats_URL =
  BASE_URL + '/vy/v0/stats/avyooer/likes/{step}/{startDate}/{numberStep}';
export const GET_TotalSharesStats_URL =
  BASE_URL + '/vy/v0/stats/avyooer/shares/{step}/{startDate}/{numberStep}';
export const GET_TotalVotesStats_URL =
  BASE_URL + '/vy/v0/stats/avyooer/votes/{step}/{startDate}/{numberStep}';
export const GET_TotalVyoosStats_URL =
  BASE_URL + '/vy/v0/stats/avyooer/frequency/{step}/{startDate}/{numberStep}';
export const GET_VyoosAllTime_URL =
  BASE_URL + '/av/v0/stats/avyooer/totalvyoos';
export const GET_VyoosCommentsStats_URL =
  BASE_URL + '/cmt/v0/stats/avyooer/vyoos/{step}/{stepDate}';
export const GET_VyoosLikesStats_URL =
  BASE_URL + '/vy/v0/stats/avyooer/vyoos/likes/{step}/{stepDate}';
export const GET_VyoosSharesStats_URL =
  BASE_URL + '/vy/v0/stats/avyooer/vyoos/shares/{step}/{stepDate}';
export const GET_VyoosStats_URL =
  BASE_URL + '/vy/v0/stats/avyooer/vyoos/frequency/{step}/{stepDate}';
export const GET_VyoosVotesStats_URL =
  BASE_URL + '/vy/v0/stats/avyooer/vyoos/votes/{step}/{stepDate}';
export const POST_COMMENTS_URL = BASE_URL + '/cmt/v0/vyoo/{vyooId}/comment/';
export const POST_FAVORITE_URL = BASE_URL + '/vy/v0/favorite/';
export const POST_REPLY_COMMENTS_URL =
  BASE_URL + '/cmt/v0/vyoo/{vyooId}/comment/{commentId}/reply';
export const POST_SHARE_URL = BASE_URL + '/vy/v0/share/';
export const POST_VOTE_URL = BASE_URL + '/vy/v0/vote';
export const POST_VYOO_LIKE_URL = BASE_URL + '/vy/v0/vyoo/like/';
export const REGISTER_URL = BASE_URL + '/usr/v0/register';
export const SHARED_VYOO_URL = BASE_URL + '/vy/v0/vyoo/share/';
export const USER_URL = BASE_URL + '/usr/v0';
export const VYOOS_RESULTS_URL = BASE_URL + '/vy/v0/vyoo/results?limit=10';
export const VYOO_URL = BASE_URL + '/vy/v0/vyoo/';
export const VYOOS_URL = BASE_URL + '/vy/v0/vyoo?limit=10';
export const VYOOS_AVYOOERS_URL = BASE_URL + '/av/v0/vyoos/avyooers?';
export const QUICK_ANSWERS_URL = BASE_URL + '/vy/v0/';
export const REPORT_REASONS_URL = BASE_URL + '/vy/v0/';
export const POST_REPORT_VYOO_URL = BASE_URL + '/vy/v0/vyoo/report/';

let fetchApi = async (dispatch, action, url, responses = {}, options = {}) => {
  dispatch(loading(action));
  if (await refreshToken()) {
    options.headers.Authorization =
      action === UPDATE_AVYOOER_PASSWORD
        ? `Bearer ${localStorage.accessToken()}`
        : `Bearer ${localStorage.token()}`;
  } else {
    options.headers.Authorization = `Basic ${base64.encode(
      'avyooerId:' + localStorage.avyooerId()
    )}`;
  }
  fetch(url, options)
    .then((res) => {
      if (res.status === 200) {
        res.json().then((json) =>
          dispatch({
            type: action,
            payload: json,
          })
        );
      } else {
        dispatch({
          type: action,
          error: responses[res.status] || responses.error,
          errorMessage: res.body,
        });
      }
    })
    .catch((err) =>
      dispatch({ type: action, error: responses.error, errorMessage: err })
    );
};

let refreshToken = async () => {
  let token = localStorage.token();
  let refreshToken = localStorage.refreshToken();
  if (token && refreshToken) {
    let tokenExpiration = jwtDecode(token).exp;
    if (tokenExpiration && new Date().getTime() > tokenExpiration * 1000) {
      let res = await fetch(AUTH + '/refresh', {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.token()}`,
        },
        body: JSON.stringify({ refresh_token: refreshToken }),
      });
      if (res.status === 200) {
        res = await res.json();
        localStorage.putToken(res.token);
        localStorage.putAccessToken(res.access);
        res.refresh && localStorage.putRefreshToken(res.refresh);
        return res.token;
      } else {
        localStorage.removeToken();
        localStorage.removeAccessToken();
        localStorage.removeRefreshToken();
        localStorage.removeAvyooer();
      }
    } else {
      return token;
    }
  }
};

export const get = (dispatch, action, url, responses, options = {}) => {
  Object.assign(options, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.token()}`,
    },
  });
  fetchApi(dispatch, action, url, responses, options);
};

export const post = (dispatch, action, url, body, responses, options = {}) => {
  Object.assign(options, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.token()}`,
    },
    body: JSON.stringify(body),
  });
  fetchApi(dispatch, action, url, responses, options);
};

export const put = (dispatch, action, url, body, responses, options = {}) => {
  Object.assign(options, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${localStorage.token()}`,
    },
    body: JSON.stringify(body),
  });
  fetchApi(dispatch, action, url, responses, options);
};

export const remove = (dispatch, action, url, responses, options = {}) => {
  Object.assign(options, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${localStorage.token()}`,
    },
  });
  fetchApi(dispatch, action, url, responses, options);
};
