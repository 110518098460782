import { createMuiTheme } from '@material-ui/core/styles';
import palette from './palette';
import typography from './typography';
import { colors } from '@material-ui/core';

export const theme = {
  palette,
  typography,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: colors.grey[100],
          fontFamily:
            ' -apple-system, BlinkMacSystemFont, Helvetica Neue, SegoeUI ,Droid Sans, Roboto ,Fira Sans,Ubuntu,Cantarell, Oxygen, sansSerif',
          fontSize: '16px',
        },
      },
    },
  },
  props: {
    MuiAppBar: {
      elevation: 0,
    },
    MuiDialog: {
      style: {
        maxWidth: '400px',
        margin: 'auto',
      },
    },
    MuiToolbar: {
      style: {
        backgroundColor: palette.common.white,
        borderBottom: `4px solid ${palette.primary.dark}`,
      },
    },
    MuiDivider: {
      style: {
        backgroundColor: palette.primary.dark,
      },
    },
    MuiSvgIcon: {
      style: {
        fill: palette.primary.dark,
      },
    },
    MuiTab: {
      style: {
        color: palette.primary.dark,
      },
    },
    MuiContainer: {
      style: {
        position: 'relative',
      },
    },
  },
};
