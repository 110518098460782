import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';

import './ActionError.css';

const ActionError = ({ t, errors, action, className }) => {
  return (
    <Typography
      color="error"
      className={'action-error ' + className}
      dangerouslySetInnerHTML={{
        __html: errors[action] ? t(errors[action]) : ' ',
      }}
    />
  );
};

export default connect((state) => state)(
  withTranslation('errors')(ActionError)
);
