import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import HttpApi from 'i18next-http-backend'; // fallback http load
import LanguageDetector from 'i18next-browser-languagedetector';
// import * as url from './store/api';
import * as localStorage from './store/localStorage';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: localStorage.language(),
    ns: ['common', 'errors', 'refs'],
    defaultNS: 'common',
    fallbackNS: 'common',
    detection: {
      order: ['navigator'],
      lookupLocalStorage: 'language',
      caches: ['localStorage'],
    },
    backend: {
      backends: [
        HttpApi, // fallback
        LocalStorageBackend, // primary
      ],
      backendOptions: [
        {
          // prefix for stored languages
          prefix: '',
          // expiration
          expirationTime: 7 * 24 * 60 * 60 * 1000,
          // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
          store: window.localStorage,
        },
        // {
        //   loadPath: url.REF_URL + 'i18n/{{lng}}',
        // },
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
    fallbackLng: 'en',
    load: 'languageOnly',
    debug: false,
    parseMissingKeyHandler: (key) => `...`,
    react: {
      wait: true,
      useSuspense: true,
    },
  });

i18n.languages = ['en', 'ar', 'fr'];

export default i18n;
