import {
  FETCH_AVYOOER_VYOOS,
  FETCH_AVYOOER_FAVORITES_VYOOS,
  FETCH_AVYOOER_VOTES_VYOOS,
  GET_AVYOOER,
  LOGOUT,
  SIGNIN,
  PUT_AVYOOER,
  VYOO_VOTE,
  VYOO_FAVORITE,
  VYOO_LIKE,
  CHOOSE_VYOOSAVYOOER_SORT,
  CHOOSE_VYOOSAVYOOER_REGION,
  UPDATE_AVYOOER_PASSWORD,
  AVYOOER_CATEGORIES,
  VYOO_SHARE,
} from '../actions';
import {
  VYOO_PARTIALLY_EDITABLE,
  SORT_TOP_NEW,
  LOCATION_WORLDWIDE,
} from '../../utils/constants';

const initialState = {
  avyooer: null,
  avyooerVyoos: [],
  avyooerFavoritesVyoos: [],
  avyooerVotedVyoos: [],
  filter: false,
  sortVyoos: SORT_TOP_NEW,
  regionVyoos: LOCATION_WORLDWIDE,
  regionDetailVyoos: '',
};

const removeDuplicatesavyooerVyoos = (state, action) => {
  let result = [];

  if (!state.filter && state.avyooerVyoos) {
    result = [...state.avyooerVyoos];
  }
  if (action.payload) {
    result = [...result, ...action.payload.vyoos];
  }
  state.avyooerVyoos = result ? result : [];
};

const removeDuplicatesavyooerFavoritesVyoos = (state, action) => {
  let result = [];
  if (!state.filter && state.avyooerFavoritesVyoos) {
    result = [...state.avyooerFavoritesVyoos];
  }
  if (action.payload) {
    result = [...result, ...action.payload.favorites];
  }
  state.avyooerFavoritesVyoos = result ? result : [];
};

const removeDuplicatesavyooerVotedVyoos = (state, action) => {
  let result = [];
  if (!state.filter && state.avyooerVotedVyoos) {
    result = [...state.avyooerVotedVyoos];
  }
  if (action.payload) {
    result = [...result, ...action.payload.votes];
  }
  state.avyooerVotedVyoos = result ? result : [];
};

const avyooerReducer = (state = initialState, action) => {
  if (!action.error) {
    switch (action.type) {
      case GET_AVYOOER:
        return {
          ...state,
          avyooer: action.payload,
        };

      case FETCH_AVYOOER_VYOOS:
        removeDuplicatesavyooerVyoos(state, action);
        return {
          ...state,
          avyooerVyoos: [...state.avyooerVyoos],
          filter: false,
        };

      case FETCH_AVYOOER_FAVORITES_VYOOS:
        removeDuplicatesavyooerFavoritesVyoos(state, action);
        return {
          ...state,
          avyooerFavoritesVyoos: [...state.avyooerFavoritesVyoos],
          filter: false,
        };

      case FETCH_AVYOOER_VOTES_VYOOS:
        removeDuplicatesavyooerVotedVyoos(state, action);
        return {
          ...state,
          avyooerVotedVyoos: [...state.avyooerVotedVyoos],
          filter: false,
        };

      case PUT_AVYOOER:
        return {
          ...state,
          avyooer: {
            ...action.payload,
            avatar: action.payload.avatar
              ? action.payload.avatar
              : state.avyooer.avatar,
          },
        };

      case AVYOOER_CATEGORIES:
        state.avyooer = {
          ...state.avyooer,
          categories: action.payload,
        };
        return {
          ...state,
        };

      case VYOO_VOTE: {
        state.avyooerVyoos = state.avyooerVyoos.map((vyoo) =>
          vyoo.id === action.payload.vyooId
            ? {
                ...vyoo,
                isVoted: true,
                totalVotes: action.payload.totalVotes,
                answers: action.payload.answers,
                answer: action.payload.answer,
                editable: VYOO_PARTIALLY_EDITABLE,
              }
            : vyoo
        );
        state.avyooerFavoritesVyoos = state.avyooerFavoritesVyoos.map((vyoo) =>
          vyoo.id === action.payload.vyooId
            ? {
                ...vyoo,
                isVoted: true,
                totalVotes: action.payload.totalVotes,
                answers: action.payload.answers,
                answer: action.payload.answer,
              }
            : vyoo
        );

        return {
          ...state,
        };
      }

      case VYOO_LIKE: {
        state.avyooerVyoos = state.avyooerVyoos.map((vyoo) =>
          vyoo.id === action.payload.vyooId
            ? {
                ...vyoo,
                totalLikes: action.payload.totalLikes,
                isLiked: action.payload.isLiked,
              }
            : vyoo
        );
        state.avyooerFavoritesVyoos = state.avyooerFavoritesVyoos.map((vyoo) =>
          vyoo.id === action.payload.vyooId
            ? {
                ...vyoo,
                totalLikes: action.payload.totalLikes,
                isLiked: action.payload.isLiked,
              }
            : vyoo
        );
        state.avyooerVotedVyoos = state.avyooerVotedVyoos.map((vyoo) =>
          vyoo.id === action.payload.vyooId
            ? {
                ...vyoo,
                totalLikes: action.payload.totalLikes,
                isLiked: action.payload.isLiked,
              }
            : vyoo
        );
        return {
          ...state,
        };
      }

      case VYOO_FAVORITE: {
        state.avyooerVyoos = state.avyooerVyoos.map((vyoo) =>
          vyoo.id === action.payload.vyooId
            ? { ...vyoo, isFavorite: action.payload.isFavorite }
            : vyoo
        );

        state.avyooerFavoritesVyoos = state.avyooerFavoritesVyoos.map((vyoo) =>
          vyoo.id === action.payload.vyooId
            ? {
                ...vyoo,
                isFavorite: action.payload.isFavorite,
              }
            : vyoo
        );

        state.avyooerVotedVyoos = state.avyooerVotedVyoos.map((vyoo) =>
          vyoo.id === action.payload.vyooId
            ? { ...vyoo, isFavorite: action.payload.isFavorite }
            : vyoo
        );
        return {
          ...state,
        };
      }

      case VYOO_SHARE:
        state.avyooerVyoos = state.avyooerVyoos.map((vyoo) =>
          vyoo.id === action.payload.vyooId
            ? {
                ...vyoo,
                totalShares: action.payload.totalShares,
              }
            : vyoo
        );
        state.avyooerFavoritesVyoos = state.avyooerFavoritesVyoos.map((vyoo) =>
          vyoo.id === action.payload.vyooId
            ? {
                ...vyoo,
                totalShares: action.payload.totalShares,
              }
            : vyoo
        );
        state.avyooerVotedVyoos = state.avyooerVotedVyoos.map((vyoo) =>
          vyoo.id === action.payload.vyooId
            ? {
                ...vyoo,
                totalShares: action.payload.totalShares,
              }
            : vyoo
        );
        return {
          ...state,
        };

      case CHOOSE_VYOOSAVYOOER_SORT:
        return {
          ...state,
          sortVyoos: action.sortVyoos,
          filter: true,
        };

      case CHOOSE_VYOOSAVYOOER_REGION:
        return {
          ...state,
          regionVyoos: action.regionVyoos,
          regionDetailVyoos: action.regionDetailVyoos,
          filter: true,
        };

      case UPDATE_AVYOOER_PASSWORD:
        return {
          ...state,
        };

      case LOGOUT:
      case SIGNIN:
        return initialState;

      default:
        return { ...state };
    }
  } else {
    return state;
  }
};
export default avyooerReducer;
