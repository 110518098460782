export default `
    <p>
        AVYOO is the community where you can add value to your own opinion, to make it more valuable. You can ask any question about any topic, Vote on anything any time, and share your views with your friends and the whole world.
        <br /><br />
        AVYOO provides for all who feel their opinion is not being heard, counted, or valued, an opportunity to share their views through a simple and quick poll on topics you care or are passionate about.  It's the best way to have your say and make it heard!
        <br /><br />
        AVYOO aims to give anyone the tools to share his view with anyone else and collect different opinions, which will be well presented through targeted figures and useful charts. This app gives also the possibility to start a wave of discussion about any topics, via comments on asked polls or its final results. This will not only improve our way of giving opinions but also will add value to our own opinion and easily lead to a better decision. 
        <br /> <br />
        And the best part, it’s for free and easy to use on your phone, tablet or PC. 
    </p>
`;

