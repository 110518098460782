import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import './styles.css';
import palette from '../../theme/palette';
import { withTranslation } from 'react-i18next';

const Modal = ({
  t,
  children,
  title,
  subtitle,
  fullScreen,
  open,
  onClose,
  apply,
  cancel,
  applyLabel = 'button.apply',
  disabled = false,
}) => {
  return (
    <Dialog
      open={open}
      fullScreen={!!fullScreen}
      onClose={onClose}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      PaperProps={{
        className: !!fullScreen ? 'fullScreen-modal' : 'modal',
        style: { borderColor: palette.primary.dark },
      }}
    >
      <DialogTitle
        className="modal-title"
        style={{ backgroundColor: palette.background.dark }}
      >
        <Typography
          variant="subtitle1"
          component="div"
          style={{ display: 'grid' }}
        >
          <b>{title}</b>
          {subtitle}
        </Typography>
        <CancelIcon color="primary" onClick={onClose} />
      </DialogTitle>
      <DialogContent className="modal-content">{children}</DialogContent>
      {apply && (
        <DialogActions className="modal-actions">
          {cancel && (
            <Button
              autoFocus
              onClick={() => {
                cancel();
                onClose();
              }}
              style={{ color: palette.primary.dark }}
            >
              {t('button.cancel')}
            </Button>
          )}
          {apply && (
            <Button
              autoFocus
              onClick={() => {
                apply();
                onClose();
              }}
              style={{ color: disabled ? 'gray' : palette.primary.dark }}
              disabled={disabled}
            >
              {t(applyLabel)}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default withTranslation()(Modal);
