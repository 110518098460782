import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

const palette = {
  common: {
    black,
    white,
  },

  background: {
    default: '#F4F6F8',
    paper: white,
    dark: colors.grey[100],
  },

  primary: {
    contrastText: white,
    dark: colors.lightGreen[500],
    main: colors.lightGreen[200],
    light: 'rgb(232, 247, 216)',
  },

  secondary: {
    contrastText: white,
    dark: colors.lightBlue[800],
    main: colors.lightBlue[500],
    light: colors.lightBlue[200],
  },

  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },

  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },

  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },

  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },

  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[500],
    link: colors.blue[600],
  },

  // icon: colors.blueGrey[600],
  // divider: colors.grey[200],
  // favorisIcon :{
  //   default : '#cfd8dc',
  //   active :'#ffeb3b'
  // } ,
};

export default palette;
