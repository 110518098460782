import * as api from '../api';
import * as localStorage from '../localStorage';

export const SIGNUP = 'SIGNUP';
export const SIGNIN = 'SIGNIN';
export const LOGOUT = 'LOGOUT';

export const errors = {
  ERR_SIGNUP: 'errors.signupError',
  ERR_SIGNUP_PASSWORD_POLICY: 'errors.signupPasswordPolicyError',
  ERR_SIGNUP_USER_EXISTS: 'errors.signupUserExistsError',
  ERR_SIGNIN: 'errors.signinError',
};

export const signup = (username, password, name) => (dispatch) =>
  api.post(
    dispatch,
    SIGNUP,
    api.REGISTER_URL,
    {
      username,
      password,
      name,
      id: localStorage.guestAvyooerId(),
    },
    {
      400: errors.ERR_SIGNUP_PASSWORD_POLICY,
      409: errors.ERR_SIGNUP_USER_EXISTS,
      error: errors.ERR_SIGNUP,
    }
  );

export const signin = (email, password) => (dispatch) =>
  api.post(
    dispatch,
    SIGNIN,
    api.AUTH,
    {
      email,
      password,
    },
    {
      error: errors.ERR_SIGNIN,
    }
  );

export const logOut = () => {
  return {
    type: LOGOUT,
  };
};
