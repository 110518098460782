import * as actions from '../actions';

const initialState = {};

const errorReducer = (state = initialState, action) => {
  if (action.error) {
    state[action.type] = action.error;
  } else if (state[action.type]) {
    delete state[action.type];
  }

  if (action.type === actions.LOADING && action.payload.loading) {
    delete state[action.payload.action];
  }

  if (action.type === actions.CLEAR_ERROR) {
    delete state[action.payload];
  }

  return { ...state };
};

export default errorReducer;
