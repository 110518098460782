export default `
    Cette politique de confidentialité explique comment AVYOO utilise et protège les informations que vous fournissez lors de l'utilisation de l'application ou du site Web.
    <br /> <br />
    AVYOO considère votre vie privée comme très importante et la prend au sérieux. Cette politique de confidentialité décrit nos politiques et procédures sur la collecte, l'utilisation, la divulgation et le partage de vos informations personnelles ou données personnelles lorsque vous utilisez la plate-forme AVYOO. Nous maintiendrons une extrême confidentialité sur ces informations. Il ne sera utilisé ou partagé que de la manière décrite dans la présente politique de confidentialité. En utilisant les services AVYOO, vous consentez à la collecte, au transfert, au stockage, à la divulgation et à d'autres utilisations de vos informations personnelles comme décrit dans cette politique de confidentialité.
    <br /> <br />
    AVYOO peut modifier cette politique de temps à autre en mettant à jour cette page. Vous serez averti pour vous assurer que vous êtes satisfait de tout changement. Si vous continuez à utiliser AVYOO sans consentir aux modifications ou vous désinscrire, il sera considéré que vous avez consenti aux nouvelles modifications. Cette politique est en vigueur à partir du 11 mai 2020.
    <br /> <br />
    Dans cette politique de confidentialité, le terme «nous» ou «nous» désigne le propriétaire de la plateforme AVYOO, tandis que le terme «vous» ou «le vôtre» désigne l'utilisateur ou le spectateur d'AVYOO.
    <br /> <br />
    <b>Pourquoi vos informations sont-elles collectées?</b>
    <br /> <br />
    Les informations collectées par AVYOO sont principalement utilisées pour personnaliser le contenu afin de bien vous servir, en vous présentant les sondages qui correspondent à votre profil et à vos centres d'intérêt. Les informations conservées sont nécessaires pour faciliter un accès efficace à votre compte, superviser des mesures collectives telles que le nombre total de visiteurs, le trafic et les tendances démographiques, et suivre le contenu et les utilisateurs de l'utilisateur. En outre, ces données sont nécessaires pour évaluer la façon dont le service est utilisé, détecter les problèmes de service ou techniques et assurer la sécurité.
    <br /> <br />
    <b>Quel type d'informations collectons-nous?</b>
    <br /> <br />
    Lorsque vous créez un compte avec AVYOO, nous recueillons les informations nécessaires à votre sujet, telles que votre nom, votre adresse e-mail, votre état civil, votre profession, votre date de naissance, votre sexe, votre lieu et votre photo de profil, ainsi que vos catégories préférées de sujets de sondage. Une fois votre profil créé, votre nom d'utilisateur et des informations de base limitées sur le contenu de votre profil sont accessibles au public. Les utilisateurs enregistrés d'AVYOO pourront voir certaines informations sur votre page de profil, telles que les sondages que vous publiez, vos abonnés et les personnes que vous suivez, sauf si vous rendez certaines de ces informations privées.
    <br /> <br />
    Nous recueillons également vos informations de profil de base lorsque vous vous connectez à AVYOO via une connexion aux réseaux sociaux, tels que Facebook ou Google, ou LinkedIn. Ces réseaux liés peuvent également apparaître dans votre profil. Les informations spécifiques qui nous sont fournies sont déterminées par ces tiers et peuvent varier selon le réseau.
    <br /> <br />
    Nous recueillons et stockons les informations et le contenu que vous publiez sur la plate-forme AVYOO, y compris vos sondages, votes, likes, commentaires et informations de profil de base. Votre contenu (à l'exception de vos votes), la date et l'heure sont visibles publiquement sur la plateforme AVYOO, avec votre nom.
    <br /> <br />
    De plus, nous utilisons des technologies de suivi de base (cookies,…) pour collecter automatiquement des informations sur vos activités, telles que vos recherches, pages vues, date et heure de votre visite, et d'autres informations sur vos informations de navigation, telles que l'adresse IP et la localisation , URL, identifiants publicitaires ou de contenu uniques (le cas échéant) et fuseau horaire, et autres informations sur les activités des utilisateurs sur la plateforme AVYOO.
    <br /> <br />
    Si vous choisissez d’inviter un ami via AVYOO, il vous sera demandé de fournir l’adresse e-mail de cette personne. Une invitation par e-mail unique sera envoyée automatiquement à votre ami. AVYOO conserve ces informations pour envoyer des invitations, pour inscrire votre ami si votre invitation est acceptée et pour garder une trace du succès de notre service d'invitation.
    <br /> <br />
    Lorsque vous communiquez avec nous (par e-mail, via la plateforme AVYOO ou autrement), nous pouvons conserver un enregistrement de votre communication. En divulguant vos informations de contact à AVYOO, vous consentez à ce que nous utilisions vos informations de contact pour communiquer avec vous.
    <br /> <br />
    <b>Combien de temps AVYOO conservera vos informations personnelles?</b>
    <br /> <br />
    Vos informations personnelles seront conservées indéfiniment jusqu'à ce que vous supprimiez votre compte. Vous pouvez supprimer à tout moment votre compte et toutes les données associées. Pour ce faire, veuillez visiter votre page de paramètres AVYOO.
    <br /> <br />
    <b>Comment utilisons-nous vos informations?</b>
    <br /> <br />
    Nous ne vendons pas vos données personnelles, telles que votre nom et vos coordonnées, à des tiers pour les utiliser à leurs propres fins de marketing. AVYOO utilise les informations que nous collectons aux fins suivantes:
    <br /> <br />
    <li>
    Adapter le contenu et les informations en fonction de vos besoins, afin de personnaliser vos expériences lors de l'utilisation de la plateforme AVYOO. Vos données nous permettront de vous proposer des sondages, des abonnés et du contenu, ainsi qu'une aide et des instructions personnalisées.
    </li>
    <li> 
        Préparer des statistiques agrégées, des mesures et d'autres rapports sur les performances et les résultats des sondages votés, en tenant compte de certaines caractéristiques des utilisateurs qui ont participé à leurs sondages, telles que leur âge, sexe, profession et informations sur la date et l'heure, sans mentionner aucun détail individuel.
    </li>
    <li> 
        Recueillir des métriques pour mieux comprendre comment les utilisateurs accèdent et utilisent la plateforme AVYOO;
    </li>
    <li>
        Évaluer et améliorer la plate-forme AVYOO, y compris les services publicitaires et la personnalisation, et développer de nouveaux produits et services.
    </li>
    <li> 
        Pour communiquer avec vous (par e-mail ou par notifications d'applications), au sujet de votre utilisation de la plateforme AVYOO, répondre à vos demandes et à d'autres fins de service client. En outre, pour vous envoyer des nouvelles et des bulletins d'information, des offres spéciales et des promotions, ou pour vous contacter d'une autre manière au sujet de produits ou d'informations que nous pensons susceptibles de vous intéresser, y compris des informations sur les produits et services tiers. Vous aurez la possibilité de vous désabonner de la réception de tels e-mails périodiques de notre part ou de désactiver ces notifications.
    </li>
    <li> 
        Pour se conformer aux obligations légales, dans le cadre de nos opérations commerciales générales et à d'autres fins d'administration commerciale.
    </li>
    <li>
        Pour éviter toute utilisation abusive et prendre des mesures concernant des activités illégales, des soupçons de fraude, des situations impliquant des menaces potentielles pour la sécurité de toute personne, ou des violations de nos Conditions d'utilisation ou de cette Politique de confidentialité.
    </li>
    <br /> <br />
    <b>Comment partageons-nous vos informations?</b>
    <br /> <br />
    Votre contenu, y compris votre nom, votre photo de profil, votre mini-bio et certaines informations d'activité associées peuvent être consultés par d'autres utilisateurs enregistrés d'AVYOO. Les visiteurs non enregistrés ne peuvent pas voir votre profil, mais ils ne peuvent voir et voter que sur vos sondages.
    <br /> <br />
    Nous pouvons partager publiquement les statistiques globales, les métriques et autres rapports sur les résultats des sondages votés ou le contenu de la plateforme AVYOO. Nous ne partageons pas les adresses IP ou les informations personnelles telles que votre nom.
    <br /> <br />
    Nous pouvons partager des informations agrégées avec des tiers à des fins de recherche, de marketing, d'analyse et à d'autres fins, sans identifier une personne en particulier.
    <br /> <br />
    Nous pouvons partager des informations sur votre activité avec des prestataires de services tiers qui utilisent ces informations pour nous fournir des services, tels que des processeurs de paiement, des hébergeurs, des auditeurs, des conseillers, des consultants, des prestataires de service client et d'assistance. Mais ce faisant, nous ne compromettons pas vos informations personnelles.
    <br /> <br />
    Nous pouvons divulguer vos informations si nous sommes tenus de le faire par la loi, et lorsque nous le jugeons nécessaire pour répondre aux réclamations formulées contre nous ou, pour nous conformer à une procédure légale, appliquer ou administrer nos accords et conditions, pour la prévention de la fraude, l'évaluation des risques, enquête et de protéger les droits, la propriété ou la sécurité d'AVYOO, de ses utilisateurs ou d'autres.
    <br /> <br />
    <b>Comment protégeons-nous vos informations?</b>
    <br /> <br />
    La sécurité de vos informations est très importante pour nous. AVYOO a mis en place des garanties pour protéger les informations que nous collectons. Cependant, aucun site Web ou transmission Internet n'est totalement sécurisé. Nous vous exhortons à prendre des mesures pour protéger vos informations personnelles, telles que choisir un mot de passe fort et le garder privé, ainsi que vous déconnecter de votre compte utilisateur et fermer votre navigateur Web lorsque vous avez terminé d'utiliser la plate-forme AVYOO sur un site partagé ou non sécurisé. dispositif.
    <br /> <br />
    <b>Comment gérez-vous vos informations?</b>
    <br /> <br />
    Vous pouvez mettre à jour ou corriger les informations de votre compte à tout moment en vous connectant à votre compte. Vous pouvez ajuster vos paramètres de confidentialité pour contrôler l'affichage de vos informations. Vous pouvez également gérer les types de notifications et de communications que nous envoyons, limiter les informations partagées au sein d'AVYOO à votre sujet, et autrement modifier certains paramètres de confidentialité.
    <br /> <br />
    Selon votre contenu, vous pouvez modifier et supprimer les sondages que vous créez à tout moment, tant qu'ils n'ont pas de votes. Au-delà, les informations peuvent s'avérer utiles à des fins de recherche ou à d'autres fins significatives et le sondage deviendra automatiquement la propriété d'AVYOO.
    <br /> <br />
    Vous choisissez de supprimer votre compte à tout moment, mais tous vos sondages seront supprimés de la visibilité publique sur l'AYOO, et il se peut qu'ils ne soient pas restaurés par nous, même si vous changez d'avis. Et vous pouvez également choisir de désactiver votre compte, vous ne recevrez plus aucune communication de notre part et les utilisateurs ne pourront plus interagir avec vous; Cependant, votre contenu restera sur l'AVYOO et vous pourrez le réactiver à tout moment en choisissant de vous connecter.
    <br /> <br />
    Le contenu réservé aux adultes peut être automatiquement supprimé, ainsi que tout sondage s'il est signalé comme abus par de nombreux utilisateurs et peut s'avérer trop sensible pour l'utilisateur général. Notre objectif est de faire d'AVYOO une plate-forme sûre et facile pour tout le monde.
    <br /> <br />
    <b>Qu'en est-il des liens vers d'autres sites Web?</b>
    <br /> <br />
    La plate-forme AVYOO peut contenir des liens vers des sites tiers ou des services en ligne ou du contenu multimédia provenant d'autres sites Web. Nous ne sommes pas responsables des pratiques de ces tiers, dont les pratiques d'information sont soumises à leurs propres politiques et procédures, et non à cette politique de confidentialité.
    <br /> <br />
    <b>Comment nous contactez-vous?</b>
    <br /> <br />
    Si vous avez des questions ou des griefs concernant nos pratiques ou cette politique de confidentialité, veuillez nous envoyer un e-mail à admin@avyoo.com

`;