import * as actions from '../actions';

const initialState = [];

const loadingReducer = (state = initialState, action) => {
  if (action.type === actions.LOADING) {
    state.push(action.payload.action);
    return [...state];
  } else {
    if (state.indexOf(action.type) > -1) {
      state.splice(state.indexOf(action.type), 1);
      return [...state];
    } else {
      return state;
    }
  }
};

export default loadingReducer;
