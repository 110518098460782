import * as api from '../api';
import * as localStorage from '../localStorage';
import { avyooer, avyooerId } from '../localStorage';
import { SORT_TOP_NEW } from '../../utils/constants';

export const FETCH_VYOOS = 'FETCH_VYOOS';
export const FETCH_VYOOS_RESULTS = 'FETCH_VYOOS_RESULTS';
export const FETCH_VYOOS_AVYOOERS = 'FETCH_VYOOS_AVYOOERS';
export const VYOO_VOTE = 'VYOO_VOTE';
export const VYOO_SHARE = 'VYOO_SHARE';
export const VYOO_FAVORITE = 'VYOO_FAVORITE';
export const VYOO_LIKE = 'VYOO_LIKE';
export const VYOO_NEW = 'VYOO_NEW';
export const FETCH_VYOO = 'FETCH_VYOO';
export const SET_CONFIRM_VOTE = 'SET_CONFIRM_VOTE';
export const SET_CARD_VIEW = 'SET_CARD_VIEW';
export const LIST_VIEW_HIGHLIGHT = 'LIST_VIEW_HIGHLIGHT';
export const CLEAR_LIST_VIEW_HIGHLIGHT = 'CLEAR_LIST_VIEW_HIGHLIGHT';
export const VYOO_ADD = 'VYOO_ADD';
export const VYOO_CLEAR = 'VYOO_CLEAR';
export const FETCH_SHARED_VYOO = 'FETCH_SHARED_VYOO';
export const CHOOSE_VYOOS_SORT = 'CHOOSE_VYOOS_SORT';
export const CHOOSE_VYOOS_REGION = 'CHOOSE_VYOOS_REGION';
export const SET_GUEST_TOTALVYOOS = 'SET_GUEST_TOTALVYOOS';
export const VYOO_TURN_OFF = 'VYOO_TURN_OFF';
export const VYOO_DELETE = 'VYOO_DELETE';
export const VYOO_PUT = 'VYOO_PUT';
export const FETCH_QUICK_ANSWERS = 'FETCH_QUICK_ANSWERS';
export const VYOO_DETAILS = 'VYOO_DETAILS';
export const FETCH_REPORT_REASONS = 'FETCH_REPORT_REASONS';
export const VYOO_REPORT = 'VYOO_REPORT';
export const REPORTED_VYOO = 'REPORTED_VYOO';

export const errors = {
  ERR_FETCH_VYOOS: 'errors.fetchVyoos',
  ERR_NO_VYOOS: 'errors.noVyoos',
  ERR_FETCH_VYOOS_RESULTS: 'errors.fetchVyoosResults',
  ERR_NO_VYOOS_RESULTS: 'errors.noVyoosResults',
  ERR_FETCH_VYOOS_AVYOOERS: 'errors.noVyoosAvyooers',
  ERR_NO_VYOOS_AVYOOERS: 'errors.noVyoosAvyooers',
  ERR_VYOO_VOTE: 'errors.vyooVote',
  ERR_VYOO_SHARE: 'errors.vyooShare',
  ERR_VYOO_FAVORITE: 'errors.vyooFavorite',
  ERR_VYOO_LIKE: 'errors.vyooLike',
  ERR_FETCH_VYOO: 'errors.fetchVyoo',
  ERR_NO_VYOO: 'errors.noVyoo',
  ERR_VYOO_ADD: 'errors.vyooAdd',
  ERR_FETCH_VYOO: 'errors.fetchVyoo',
  ERR_NO_VYOO: 'errors.noVyoo',
  ERR_VYOO_PUT: 'errors.vyooPut',
  ERR_FETCH_QUICK_ANSWERS: 'errors.fetchQuickAnswers',
  ERR_FETCH_REPORT_REASONS: 'errors.fetchReportReasons',
  ERR_VYOO_REPORT: 'errors.vyooReport',
};

export const fetchVyoos = (
  sort,
  region,
  regionDetail,
  last,
  categories = []
) => (dispatch) =>
  api.get(
    dispatch,
    FETCH_VYOOS,
    buildUrl(sort, region, regionDetail, last, api.VYOOS_URL, categories),
    {
      404: errors.ERR_NO_VYOOS,
      error: errors.ERR_FETCH_VYOOS,
    }
  );

export const fetchVyoosResults = (sort, region, regionDetail, last) => (
  dispatch
) =>
  api.get(
    dispatch,
    FETCH_VYOOS_RESULTS,
    buildUrl(sort, region, regionDetail, last, api.VYOOS_RESULTS_URL),
    {
      404: errors.ERR_NO_VYOOS_RESULTS,
      error: errors.ERR_FETCH_VYOOS_RESULTS,
    }
  );

export const fetchVyoosAvyooers = (vyoos) => (dispatch) =>
  api.get(
    dispatch,
    FETCH_VYOOS_AVYOOERS,
    api.VYOOS_AVYOOERS_URL +
      'ids=' +
      vyoos.map((vyoo) => vyoo.avyooer.id).toString(),
    {
      404: errors.ERR_NO_VYOOS_AVYOOERS,
      error: errors.ERR_FETCH_VYOOS_AVYOOERS,
    }
  );

export const voteVyoo = (answerId, vyooId, ownerId, shareId) => (dispatch) =>
  api.post(
    dispatch,
    VYOO_VOTE,
    api.POST_VOTE_URL,
    {
      avyooerId: avyooerId(),
      avyooerData: avyooer(),
      vyooId,
      answerId,
      ownerId,
      shareId,
    },
    {
      error: errors.ERR_VYOO_VOTE,
    }
  );

export const shareVyoo = (id, vyooId, platform, ownerId) => (dispatch) =>
  api.post(
    dispatch,
    VYOO_SHARE,
    api.POST_SHARE_URL,
    {
      vyooId,
      id,
      platform,
      ownerId,
    },
    {
      error: errors.ERR_VYOO_SHARE,
    }
  );

export const favoriteVyoo = (vyooId, ownerId) => (dispatch) =>
  api.post(
    dispatch,
    VYOO_FAVORITE,
    api.POST_FAVORITE_URL + vyooId + '/' + ownerId,
    { error: errors.ERR_VYOO_FAVORITE }
  );

export const likeVyoo = (vyooId, ownerId) => (dispatch) =>
  api.post(
    dispatch,
    VYOO_LIKE,
    api.POST_VYOO_LIKE_URL + vyooId + '/' + ownerId,
    { error: errors.ERR_VYOO_LIKE }
  );

export const newVyoo = (vyoo) => {
  return {
    type: VYOO_NEW,
    payload: vyoo,
  };
};

export const vyooDetails = (vyoo) => {
  return {
    type: VYOO_DETAILS,
    vyoo,
  };
};

export const setConfirmeVote = (confirmVote) => {
  return {
    type: SET_CONFIRM_VOTE,
    confirmVote,
  };
};

export const setCardView = (cardView) => {
  return {
    type: SET_CARD_VIEW,
    cardView,
  };
};

export const highlightListView = (vyooId) => {
  return {
    type: LIST_VIEW_HIGHLIGHT,
    vyooId: vyooId,
  };
};

export const ClearhighlightListView = () => {
  return {
    type: CLEAR_LIST_VIEW_HIGHLIGHT,
  };
};

export const clearVyooForm = () => {
  return {
    type: VYOO_CLEAR,
  };
};

export const postVyoo = (vyoo) => (dispatch) =>
  api.post(dispatch, VYOO_ADD, api.VYOO_URL, vyoo, {
    error: errors.ERR_VYOO_ADD,
  });

export const turnOffVyoo = (id) => (dispatch) =>
  api.put(dispatch, VYOO_TURN_OFF, api.VYOO_URL + id + '/turnoff', {
    error: errors.ERR_TURN_OFF_VYOO,
  });

export const deleteVyoo = (id) => (dispatch) =>
  api.remove(dispatch, VYOO_DELETE, api.VYOO_URL + id, {
    error: errors.ERR_DELETE_VYOO,
  });

export const putVyoo = (vyoo, id) => (dispatch) =>
  api.put(dispatch, VYOO_PUT, api.VYOO_URL + id, vyoo, {
    error: errors.ERR_VYOO_PUT,
  });

export const fetchSharedVyoo = (vyooId, shareId) => (dispatch) =>
  api.get(
    dispatch,
    FETCH_SHARED_VYOO,
    api.SHARED_VYOO_URL + vyooId + '?shareId=' + shareId,
    {
      404: errors.ERR_NO_VYOO,
      error: errors.ERR_FETCH_VYOO,
    }
  );

export const setVyoosSort = (sort) => {
  return {
    type: CHOOSE_VYOOS_SORT,
    sortVyoos: sort,
  };
};

export const setVyoosRegion = (region, regionDetail) => {
  return {
    type: CHOOSE_VYOOS_REGION,
    regionVyoos: region,
    regionDetailVyoos: regionDetail,
  };
};

export const settotalVyoosGuest = (count) => {
  localStorage.puttotalVyoosGuest(count - 1);
  return {
    type: SET_GUEST_TOTALVYOOS,
  };
};

export const fetchQuickAnswers = () => (dispatch) =>
  api.get(
    dispatch,
    FETCH_QUICK_ANSWERS,
    api.QUICK_ANSWERS_URL + 'quickAnswers',
    {
      error: errors.ERR_FETCH_QUICK_ANSWERS,
    }
  );

export const fetchReportReasons = () => (dispatch) =>
  api.get(
    dispatch,
    FETCH_REPORT_REASONS,
    api.REPORT_REASONS_URL + 'reportReasons',
    {
      error: errors.ERR_FETCH_REPORT_REASONS,
    }
  );

export const reportVyoo = (vyooId, ownerId, reasonId, reasonText) => (
  dispatch
) =>
  api.post(
    dispatch,
    VYOO_REPORT,
    api.POST_REPORT_VYOO_URL,
    {
      vyooId,
      ownerId,
      reasonId,
      reasonText,
    },
    {
      error: errors.ERR_VYOO_REPORT,
    }
  );

export const ReportedVyoo = (vyooId) => {
  return {
    type: REPORTED_VYOO,
    vyooId: vyooId,
  };
};

const buildUrl = (sort, region, regionDetail, last, url, categories) => {
  let queryParams = '';

  let avyooer = localStorage.avyooer();
  if (categories && categories.length > 0) {
    queryParams += '&categories=' + categories.toString();
  }

  if (avyooer && (!categories || (categories && categories.length === 0))) {
    if (avyooer.categories) {
      queryParams += '&categories=' + avyooer.categories.toString();
    }
    if (avyooer.country) {
      queryParams += '&country=' + avyooer.country;
    }
    if (avyooer.nationality) {
      queryParams += '&nationality=' + avyooer.nationality;
    }
  }

  if (region) {
    queryParams += '&region=' + region;

    if (region !== 'WW') {
      queryParams += '&detail=' + regionDetail;
    }
  }

  queryParams += '&sort=' + (sort || SORT_TOP_NEW);

  return url + (last ? '&last=' + last : '') + queryParams;
};
