import { SIGNUP, SIGNIN, LOGOUT } from '../actions';
import * as localStorage from '../localStorage';

const initialState = {
  authenticated: !!localStorage.avyooer(),
};

const authReducer = (state = initialState, action) => {
  if (!action.error) {
    switch (action.type) {
      case SIGNUP:
        return {
          ...state,
          authenticated: true,
        };

      case SIGNIN:
        return {
          ...state,
          authenticated: true,
        };

      case LOGOUT:
        return {
          authenticated: false,
        };

      default:
        return { ...state };
    }
  } else {
    return state;
  }
};
export default authReducer;
