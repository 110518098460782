export default `
        AVYOO est la communauté où vous pouvez ajouter de la valeur à votre propre opinion, pour la rendre plus précieuse. Vous pouvez poser n'importe quelle question sur n'importe quel sujet, voter sur n'importe quoi à tout moment et partager vos opinions avec vos amis et le monde entier.
        <br /><br />
        AVYOO offre à tous ceux qui estiment que leur opinion n'est pas entendue, comptée ou appréciée, une opportunité de partager leurs points de vue à travers un sondage simple et rapide sur des sujets qui vous tiennent à cœur ou qui vous passionnent. C'est la meilleure façon de vous faire entendre et de vous faire entendre!
        <br /><br />
        AVYOO vise à donner à n'importe qui les outils pour partager son point de vue avec n'importe qui d'autre et recueillir des opinions différentes, qui seront bien présentées à travers des chiffres ciblés et des graphiques utiles. Cette application donne également la possibilité de lancer une vague de discussion sur n'importe quel sujet, via des commentaires sur les sondages demandés ou ses résultats finaux. Cela améliorera non seulement notre façon de donner des opinions, mais ajoutera également de la valeur à notre propre opinion et conduira facilement à une meilleure décision.
        <br /> <br />
        Et le meilleur, c'est qu'il est gratuit et facile à utiliser sur votre téléphone, tablette ou PC.
`;

