import store from '../store';
import { CLEAR_ERROR } from '../store/actions';

export const isLoading = (action) => {
  let state = store.getState();
  return action
    ? state?.loading?.indexOf(action) > -1
    : state?.loading?.length > 0;
};

export const actionError = (action) => {
  let state = store.getState();
  return state.errors[action];
};

export const clearActionError = (action) => {
  store.dispatch({ type: CLEAR_ERROR, payload: action });
};
