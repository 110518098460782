import React, { lazy, useState, useEffect } from 'react';
import './App.css';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { RedirectContext } from './utils/redirect';
import AboutUs from './pages/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import UsersAgreement from './pages/UsersAgreement';
import { useTranslation } from 'react-i18next';
import {
  MuiThemeProvider,
  StylesProvider,
  createMuiTheme,
  jssPreset,
} from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { theme } from './theme';

const VyooRoutes = lazy(() => import('./pages/vyoo'));
const AccountRoutes = lazy(() => import('./pages/account'));
const SettingsRoutes = lazy(() => import('./pages/setting'));
const ActivitiesRoutes = lazy(() => import('./pages/activities'));

const App = () => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const [previousPath, setPreviousPath] = useState();

  const setRedirect = (newPath) => {
    setPreviousPath(history.location.pathname);
    history.push(newPath);
  };

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n.dir()]);

  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
  const themeWithDirection = createMuiTheme({
    ...theme,
    direction: i18n.dir(),
  });

  return (
    <div className="App">
      <RedirectContext.Provider value={{ setRedirect, previousPath }}>
        <StylesProvider jss={jss}>
          <MuiThemeProvider theme={themeWithDirection}>
            <Switch>
              <Route exact path="/" component={VyooRoutes} />
              <Route path="/vyoo/" component={VyooRoutes} />
              <Route path="/share/" component={VyooRoutes} />
              <Route path="/account/" component={AccountRoutes} />
              <Route path="/setting" component={SettingsRoutes} />
              <Route path="/aboutus" component={AboutUs} />
              <Route path="/privacy" component={PrivacyPolicy} />
              <Route path="/usersagreements" component={UsersAgreement} />
              <Route path="/activities/" component={ActivitiesRoutes} />
              <Redirect to="/" />
            </Switch>
          </MuiThemeProvider>
        </StylesProvider>
      </RedirectContext.Provider>
    </div>
  );
};

export default App;
