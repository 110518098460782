export default `
توضح سياسة الخصوصية هذه كيف يستخدم أفيو ويحمي أي معلومات تقدمها أثناء استخدام التطبيق أو الموقع.
<br /> <br />
تعتبر أفيو خصوصيتك مهمة للغاية وتأخذها على محمل الجد. تصف سياسة الخصوصية هذه سياساتنا وإجراءاتنا المتعلقة بجمع معلوماتك الشخصية أو بياناتك الشخصية واستخدامها والكشف عنها ومشاركتها عند استخدام منصة أفيو. سنحافظ على السرية القصوى حول هذه المعلومات. سيتم استخدامه أو مشاركته فقط بالطريقة الموضحة في سياسة الخصوصية هذه. باستخدام خدمات أفيو، فإنك توافق على جمع ونقل وتخزين وكشف واستخدامات أخرى لمعلوماتك الشخصية كما هو موضح في سياسة الخصوصية هذه.
<br /> <br />
قد تقوم أفيوبتغيير هذه السياسة من وقت لآخر عن طريق تحديث هذه الصفحة. سيتم إخطارك للتأكد من رضاك ​​عن أي تغييرات. من خلال الاستمرار في استخدام أفيو دون الموافقة على التغييرات أو إلغاء الاشتراك ، سيتم اعتبار أنك قد وافقت على التغييرات الجديدة. تسري هذه السياسة اعتبارًا من 11 مايو 2020.
<br /> <br />
في سياسة الخصوصية هذه ، يشير المصطلح "نحن" أو "نحن" إلى مالك منصة أفيو، بينما يشير المصطلح "أنت" أو "لك" إلى مستخدم أو عارض أفيو.
<br /> <br />
<b>لماذا يتم جمع معلوماتك؟</b>
<br /> <br />
يتم استخدام المعلومات التي تم جمعها بواسطة أفيو بشكل أساسي لتخصيص المحتوى لخدمتك بشكل جيد ، من خلال تقديم استطلاعات الرأي التي تتوافق مع ملفك الشخصي واهتماماتك. المعلومات المحتجزة ضرورية لتسهيل الوصول الفعال إلى حسابك ، والإشراف على المقاييس الجماعية مثل العدد الإجمالي للزوار ، وحركة المرور ، والأنماط الديموغرافية ، وتتبع محتوى المستخدم والمستخدمين. أيضًا ، هذه البيانات ضرورية لتقييم كيفية استخدام الخدمة ، واكتشاف الخدمة أو المشاكل التقنية ، والحفاظ على الأمان.
<br /> <br />
<b>ما نوع المعلومات التي نجمعها؟</b>
<br /> <br />
عند التسجيل للحصول على حساب في أفيو، نجمع المعلومات الضرورية عنك ، مثل اسمك ، والبريد الإلكتروني ، والحالة الاجتماعية ، والمهنة ، وتاريخ الميلاد ، والجنس ، والموقع ، وصورة الملف الشخصي ، بالإضافة إلى الفئات المفضلة لموضوعات الاستطلاعات. بمجرد إنشاء ملفك الشخصي ، يصبح اسم المستخدم والمعلومات الأساسية المحدودة لمحتوى ملفك الشخصي متاحًا للجميع. سيتمكن المستخدمون المسجلون في أفيو من رؤية معلومات معينة على صفحة ملفك الشخصي ، مثل استطلاعات الرأي التي تنشرها ، ومتابعيك ومن تتابعهم ، ما لم تجعل بعض هذه المعلومات خاصة.
<br /> <br />
نقوم أيضًا بجمع معلومات ملفك الشخصي الأساسية عند تسجيل الدخول على أفيوعبر تسجيل الدخول عبر وسائل التواصل الاجتماعي ، مثل Facebook أو Google أو LinkedIn. قد تظهر هذه الشبكات المرتبطة أيضًا في ملف التعريف الخاص بك. يتم تحديد المعلومات المحددة المقدمة إلينا من قبل هذه الأطراف الثالثة وقد تختلف حسب الشبكة.
<br /> <br />
نقوم بجمع وتخزين المعلومات والمحتوى الذي تنشره على منصة أفيو، بما في ذلك استطلاعات الرأي الخاصة بك ، والتصويت ، والإعجابات ، والتعليقات ، ومعلومات ملف التعريف الأساسية. يمكن عرض المحتوى الخاص بك (باستثناء أصواتك) وطوابع التاريخ والوقت للجمهور على منصة أفيو، جنبًا إلى جنب مع اسمك.
<br /> <br />
بالإضافة إلى ذلك ، نستخدم تقنيات التتبع الأساسية (ملفات تعريف الارتباط ، ...) لجمع معلومات حول أنشطتك تلقائيًا ، مثل عمليات البحث التي تجريها ، ومشاهدات الصفحة ، وتاريخ ووقت زيارتك ، وغيرها من المعلومات حول معلومات التصفح الخاصة بك ، مثل عنوان IP والموقع ، URL ، معرفات الإعلانات أو المحتوى الفريدة (إن وجدت) والمنطقة الزمنية ، وغيرها من المعلومات حول أنشطة المستخدم على منصة أفيو.
<br /> <br />
في حالة اختيارك دعوة صديق عبر أفيو، سيُطلب منك تقديم عنوان البريد الإلكتروني لهذا الشخص. سيتم إرسال دعوة بالبريد الإلكتروني لمرة واحدة إلى صديقك تلقائيًا. يحتفظ أفيو بهذه المعلومات لإرسال دعوات وتسجيل صديقك إذا تم قبول دعوتك ولتتبع نجاح خدمة دعوتنا.
<br /> <br />
عندما تتواصل معنا (عبر البريد الإلكتروني أو من خلال منصة أفيوأو غير ذلك) ، قد نحتفظ بسجل لاتصالاتك. من خلال الكشف عن معلومات الاتصال الخاصة بك إلى أفيو، فإنك توافق على استخدامنا لمعلومات الاتصال الخاصة بك للتواصل معك.
<br /> <br />
<b>إلى متى سيحتفظ أفيوبمعلوماتك الشخصية؟</b>
<br /> <br />
سيتم الاحتفاظ بمعلوماتك الشخصية إلى أجل غير مسمى حتى تقوم بحذف حسابك. يمكنك حذف حسابك وجميع البيانات المرتبطة في أي وقت. للقيام بذلك ، يرجى زيارة صفحة إعدادات أفيو الخاصة بك.
<br /> <br />
<b>كيف نستخدم معلوماتك؟</b>
<br /> <br />
نحن لا نبيع بvياناتك الشخصية ، مثل اسمك ومعلومات الاتصال الخاصة بك ، لأطراف ثالثة لاستخدامها لأغراض التسويق الخاصة بهم. يستخدم أفيوالمعلومات التي نجمعها للأغراض التالية:
<br /> <br />
<<li>
     لتكييف المحتوى والمعلومات وفقًا لاحتياجاتك ، من أجل تخصيص تجاربك أثناء استخدام منصة أفيو. ستسمح لنا بياناتك بأن نقترح عليك الاستطلاعات والمتابعين والمحتوى ، بالإضافة إلى المساعدة والتعليمات المخصصة.
</li>
<li>
     إعداد إحصائيات ومقاييس مجمعة وتقارير أخرى حول أداء ونتائج الاستطلاعات التي تم التصويت عليها ، مع مراعاة بعض خصائص المستخدمين الذين شاركوا في استطلاعاتهم ، مثل العمر والجنس والمهنة ومعلومات التاريخ والوقت ، دون ذكر أي تفاصيل فردية.
</li>
<li> 
    لجمع المقاييس لفهم أفضل لكيفية وصول المستخدمين إلى منصة أفيو واستخدامها ؛
</li>
<li> 
    لتقييم وتحسين منصة أفيو، بما في ذلك الخدمات الإعلانية والتخصيص ، وتطوير منتجات وخدمات جديدة.
</li>
<li>
     للتواصل معك (عبر إشعارات البريد الإلكتروني أو التطبيقات) ، حول استخدامك لمنصة أفيو، والرد على استفساراتك ، ولأغراض خدمة العملاء الأخرى. أيضًا ، لإرسال أخبار ونشرات إخبارية وعروض خاصة وعروض ترويجية ، أو للاتصال بك بشأن المنتجات أو المعلومات التي نعتقد أنها قد تهمك ، بما في ذلك معلومات حول منتجات وخدمات الطرف الثالث. ستتاح لك الفرصة لإلغاء الاشتراك من تلقي مثل هذه الرسائل الإلكترونية الدورية منا أو إلغاء تنشيط هذه الإشعارات.
</li>
<li>
     الامتثال للالتزامات القانونية ، كجزء من عملياتنا التجارية العامة ، ولأغراض إدارة الأعمال الأخرى.
</li>
<li>
     لمنع أي إساءة استخدام واتخاذ إجراءات بشأن الأنشطة غير القانونية أو الاحتيال المشتبه به أو المواقف التي تنطوي على تهديدات محتملة لسلامة أي شخص أو انتهاكات لشروط الخدمة أو سياسة الخصوصية هذه.
</li>
<br /> <br />
<b>كيف نشارك معلوماتك؟</b>
<br /> <br />
قد يتم عرض المحتوى الخاص بك ، بما في ذلك اسمك وصورة ملفك الشخصي وبعض معلومات النشاط المرتبط من قبل المستخدمين المسجلين في أفيو الآخرين. لا يمكن للزوار غير المسجلين رؤية ملفك الشخصي ، لكن يمكنهم فقط رؤية تصويتاتك والتصويت عليها
<br /> <br />
قد نشارك علنًا الإحصائيات المجمعة والمقاييس والتقارير الأخرى حول نتائج الاقتراع أو المحتوى في منصة أفيو. نحن لا نشارك عناوين IP أو المعلومات الشخصية مثل اسمك.
<br /> <br />
قد نشارك المعلومات المجمعة مع أطراف ثالثة للبحث والتسويق والتحليلات وأغراض أخرى ، دون تحديد فرد معين.
<br /> <br />
قد نشارك معلومات حول نشاطك مع مزودي خدمة من جهات خارجية يستخدمون هذه المعلومات لأداء خدمات لنا ، مثل معالجات الدفع ومقدمي الاستضافة والمدققين والمستشارين والاستشاريين وخدمة العملاء ومقدمي الدعم. ولكن عند القيام بذلك ، لن نساوم على معلوماتك الشخصية.
<br /> <br />
يجوز لنا الكشف عن معلوماتك إذا طلب منا ذلك بموجب القانون ، وعندما نعتقد أنه من الضروري الرد على المطالبات التي يتم تأكيدها ضدنا أو الامتثال للاتفاقات القانونية أو فرض أو إدارة اتفاقياتنا وشروطنا لمنع الاحتيال وتقييم المخاطر ، تحقيق وحماية حقوق أو ملكية أو سلامة أفيوأو مستخدميها أو غيرهم.
<br /> <br />
<b>كيف نحمي معلوماتك؟</b>
<br /> <br />
أمان معلوماتك مهم جدًا بالنسبة لنا. نفذت أفيوضمانات لحماية المعلومات التي نجمعها. ومع ذلك ، لا يوجد موقع ويب أو نقل عبر الإنترنت آمن تمامًا. نحثك على اتخاذ خطوات للحفاظ على معلوماتك الشخصية آمنة ، مثل اختيار كلمة مرور قوية والحفاظ عليها خاصة ، وكذلك تسجيل الخروج من حساب المستخدم الخاص بك ، وإغلاق متصفح الويب الخاص بك عند الانتهاء من استخدام أفيو على منصة مشتركة أو غير آمنة جهاز.
<br /> <br />
<b>كيف تدير معلوماتك؟</b>
<br /> <br />
يمكنك تحديث أو تصحيح معلومات حسابك في أي وقت عن طريق تسجيل الدخول إلى حسابك. يمكنك ضبط إعدادات الخصوصية الخاصة بك للتحكم في عرض معلوماتك. يمكنك أيضًا إدارة أنواع الإشعارات والاتصالات التي نرسلها ، وتقييد المعلومات التي تتم مشاركتها داخل أفيوعنك ، أو تعديل إعدادات الخصوصية المعينة.
<br /> <br />
وفقًا للمحتوى الخاص بك ، يمكنك تعديل وحذف الاستطلاعات التي تقوم بإنشائها في أي وقت ، طالما لم يكن لديهم أصوات. أبعد من ذلك ، قد تثبت المعلومات أنها مفيدة للبحث أو لأغراض أخرى ذات مغزى وسيصبح الاستطلاع تلقائيًا ملكًا لـ أفيو.
<br /> <br />
أنت تختار حذف حسابك في أي وقت ، ولكن بعد ذلك ستتم إزالة جميع استطلاعاتك من الظهور العام على أفيو، وقد لا نستعيدها حتى إذا غيرت رأيك. ويمكنك أيضًا اختيار إلغاء تنشيط حسابك ، فلن تتلقى بعد الآن أي اتصالات منا ، ولن يتمكن المستخدمون من التفاعل معك ؛ ولكن سيبقى المحتوى الخاص بك على أفيو، ويمكنك إعادة تنشيطه في أي وقت باختيار تسجيل الدخول.
<br /> <br />
قد تتم إزالة محتوى البالغين تلقائيًا ، وكذلك أي استطلاع إذا تم الإبلاغ عن إساءة استخدامه من قبل العديد من المستخدمين وقد يثبت أنه حساس للغاية بالنسبة للمستخدم العام. نحن نهدف إلى جعل أفيو منصة آمنة وسهلة للجميع.
<br /> <br />
<b>ماذا عن روابط الصفحات الالكترونية الاخرى؟</b>
<br /> <br />
قد تحتوي منصة أفيو على روابط لمواقع جهات خارجية أو خدمات عبر الإنترنت أو محتوى وسائط من مواقع أخرى. نحن لسنا مسؤولين عن ممارسات هذه الأطراف الثالثة ، التي تخضع ممارسات معلوماتها لسياساتها وإجراءاتها الخاصة ، وليس لسياسة الخصوصية هذه.
<br /> <br />
<b>كيف يمكنك الاتصال بنا؟</b>
<br /> <br />
admin@avyoo.comإذا كان لديك أي أسئلة أو شكاوى حول ممارساتنا أو سياسة الخصوصية هذه ، يرجى إرسال بريد إلكتروني إلينا على

`;