import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card, CardContent } from '@material-ui/core';
import i18nAssets from '../assets/i18n';
import AppHeader from '../common/header/AppHeader';
import { useTranslation } from 'react-i18next';

const AboutUs = ({ t }) => {
  const { i18n } = useTranslation();
  return (
    <>
      <AppHeader title={t('menu.aboutUs')} back="true" />
      <Card style={{ margin: 10 }}>
        <CardContent style={{ textAlign: 'justify', lineHeight: '1.5rem' }}>
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: i18nAssets.aboutus[i18n.language],
            }}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default withTranslation()(AboutUs);
