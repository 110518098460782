import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { Card, CardContent, Button } from '@material-ui/core';
import i18nAssets from '../assets/i18n';
import AppHeader from '../common/header/AppHeader';
import { RedirectContext } from '../utils/redirect';
import { useTranslation } from 'react-i18next';

const UsersAgreement = ({ t }) => {
  const { setRedirect } = useContext(RedirectContext);
  const { i18n } = useTranslation();

  return (
    <>
      <AppHeader title={t('menu.usersAgreement')} back="true" />
      <Card style={{ margin: 10 }}>
        <CardContent style={{ textAlign: 'justify', lineHeight: '1.5rem' }}>
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: i18nAssets.termsOfUse[i18n.language],
            }}
          />
          <div style={{ textAlign: 'center', margin: 20 }}>
            <Button
              onClick={() => setRedirect('/')}
              color="primary"
              variant="outlined"
            >
              {t('button.iUnderstand')}
            </Button>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default withTranslation()(UsersAgreement);
