import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import Modal from './Modal';

const InfoModal = ({
  title,
  cancel,
  subtitle,
  applyLabel,
  open,
  onClose,
  apply,
  Icon,
}) => {
  return (
    <Modal
      title={title}
      open={!!open}
      onClose={() => onClose()}
      applyLabel={applyLabel}
      apply={() => apply()}
      cancel={cancel && cancel}
    >
      <Grid
        container
        spacing={1}
        alignItems="center"
        style={{ margin: 0, width: '100%', flexWrap: 'nowrap' }}
      >
        <Grid item>
          <Icon fontSize="large" />
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">{subtitle}</Typography>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default withTranslation()(InfoModal);
