import React, { useContext } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import InfoModal from '../modal/InfoModal';
import withPageUtils from '../../utils/hoc/withPageUtils';
import { RedirectContext } from '../../utils/redirect';

const CreateVyooRestrict = ({ t, openCreateVyooRestrict, onClose }) => {
  const { setRedirect } = useContext(RedirectContext);
  return (
    <InfoModal
      title={t('vyoo.information')}
      subtitle={t('vyoo.createVyooRestriction')}
      open={openCreateVyooRestrict}
      onClose={() => onClose()}
      applyLabel="button.register"
      apply={() => setRedirect('/account/signup')}
      cancel={() => onClose()}
      Icon={InfoIcon}
    />
  );
};

export default withPageUtils(CreateVyooRestrict);
