import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { token } from '../../store/localStorage';
import Modal from '../../common/modal/Modal';
import LoginForm from '../../common/auth/LoginForm';
import { useSelector } from 'react-redux';
import { RedirectContext } from '../../utils/redirect';
import { routes } from '../constants';

const withPageUtils = (OriginalComponent) =>
  withTranslation()(
    withRouter((props) => {
      const [loginCheck, setLoginCheck] = useState('');
      const { setRedirect } = useContext(RedirectContext);

      const checkLoggedIn = (title) => !!token() || setLoginCheck(title);

      const authenticated = useSelector((state) => state.auth.authenticated);

      useEffect(() => {
        if (authenticated) {
          setLoginCheck('');
        } else {
          if (routes.includes(props.location.pathname)) {
            setRedirect('/');
          }
        }
      }, [authenticated]);

      if (loginCheck) {
        return (
          <>
            <Modal
              title={props.t('login.loginRequired')}
              open={!!loginCheck}
              onClose={() => setLoginCheck('')}
            >
              <LoginForm />
            </Modal>
            <OriginalComponent checkLoggedIn={checkLoggedIn} {...props} />
          </>
        );
      } else {
        return <OriginalComponent checkLoggedIn={checkLoggedIn} {...props} />;
      }
    })
  );

export default withPageUtils;
