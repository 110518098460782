import { useEffect, useRef } from 'react';

export const useEffectWithoutFirstRun = (create, inputs) => {
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    return create();
  }, inputs);
};

export default useEffectWithoutFirstRun;
