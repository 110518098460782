import React, { useContext, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Box,
  Input,
  InputAdornment,
  IconButton,
  FormHelperText,
  Button,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import './LoginForm.css';
import palette from '../../theme/palette';
import { RedirectContext } from '../../utils/redirect';
import { clearActionError, isLoading, actionError } from '../../utils/store';
import * as actions from '../../store/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import ActionError from '../error/ActionError';
import { useEffect } from 'react';

const LoginForm = ({ t }) => {
  const dispatch = useDispatch();
  const { setRedirect } = useContext(RedirectContext);

  useSelector((state) => state.errors);

  const [username, setUsername] = useState('');
  const [usernameErr, setUsernameErr] = useState('');
  const [password, setPassword] = useState('');
  const [passwordErr, setPasswordErr] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    clearActionError(actions.SIGNIN);
  }, []);

  const signin = () => {
    clearActionError(actions.SIGNIN);
    let valid = true;
    if (password === '') {
      setPasswordErr(t('account.signup.passwordRequired'));
      valid = false;
    }
    if (username === '') {
      setUsernameErr(t('account.signup.emailRequired'));
      valid = false;
    }

    if (valid) {
      dispatch(actions.signin(username, password));
    }
  };

  return (
    <Box className="login-form-container">
      <FormControl error={usernameErr !== ''}>
        <InputLabel htmlFor="username">{t('account.username')}</InputLabel>
        <Input
          id="username"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />
        <FormHelperText id="username-error">{usernameErr}</FormHelperText>
      </FormControl>
      <FormControl error={passwordErr !== ''}>
        <InputLabel htmlFor="password">{t('account.password')}</InputLabel>
        <Input
          id="password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText id="password-error">{passwordErr}</FormHelperText>
      </FormControl>
      <Button
        className="button-signin"
        variant="outlined"
        style={{ color: palette.primary.dark }}
        onClick={signin}
      >
        {isLoading(actions.SIGNIN) ? (
          <CircularProgress className="button-progress" />
        ) : (
          t('button.signIn')
        )}
      </Button>
      {actionError(actions.SIGNIN) && <ActionError action={actions.SIGNIN} />}
      <Button
        className="login-form-signup"
        style={{ color: palette.secondary.main }}
        onClick={() => setRedirect('/account/signup')}
      >
        {t('account.dontHaveAccount')}
      </Button>
    </Box>
  );
};

export default withTranslation()(LoginForm);
