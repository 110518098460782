import * as api from '../api';

export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_REGIONS = 'FETCH_REGIONS';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_MARITAL = 'FETCH_MARITAL';
export const FETCH_EDUCATION = 'FETCH_EDUCATION';
export const FETCH_PROFESSION = 'FETCH_PROFESSION';
export const FETCH_NATIONALITY = 'FETCH_NATIONALITY';

export const errors = {
  ERR_FETCH_COUNTRIES: 'errors.fetchCountries',
  ERR_NO_COUNTRIES: 'errors.noCountries',
  ERR_FETCH_REGIONS: 'errors.fetchRegions',
  ERR_NO_REGIONS: 'errors.noRegions',
  ERR_FETCH_CATEGORIES: 'errors.fetchCategories',
  ERR_NO_CATEGORIES: 'errors.noCategories',
  ERR_NO_MARITAL: 'errors.ERR_FETCH_MARITAL',
  ERR_NO_EDUCATION: 'ERR_FETCH_EDUCATION',
  ERR_NO_PROFESSION: 'ERR_FETCH_PROFESSION',
  ERR_NO_NATIONALITY: 'ERR_FETCH_NATIONALITY',
};

export const fetchCategories = () => (dispatch) =>
  api.get(dispatch, FETCH_CATEGORIES, api.REF_URL + 'category', {
    404: errors.ERR_NO_CATEGORIES,
    error: errors.ERR_FETCH_CATEGORIES,
  });

export const fetchCountries = () => (dispatch) =>
  api.get(dispatch, FETCH_COUNTRIES, api.REF_URL + 'country', {
    404: errors.ERR_NO_COUNTRIES,
    error: errors.ERR_FETCH_COUNTRIES,
  });

export const fetchRegions = () => (dispatch) =>
  api.get(dispatch, FETCH_REGIONS, api.REF_URL + 'region', {
    404: errors.ERR_NO_REGIONS,
    error: errors.ERR_FETCH_REGIONS,
  });

export const fetchMarital = () => (dispatch) =>
  api.get(dispatch, FETCH_MARITAL, api.REF_URL + 'marital', {
    404: errors.ERR_NO_MARITAL,
    error: errors.ERR_FETCH_MARITAL,
  });

export const fetchEducation = () => (dispatch) =>
  api.get(dispatch, FETCH_EDUCATION, api.REF_URL + 'education', {
    404: errors.ERR_NO_EDUCATION,
    error: errors.ERR_FETCH_EDUCATION,
  });

export const fetchProfession = () => (dispatch) =>
  api.get(dispatch, FETCH_PROFESSION, api.REF_URL + 'profession', {
    404: errors.ERR_NO_PROFESSION,
    error: errors.ERR_FETCH_PROFESSION,
  });

export const fetchNationality = () => (dispatch) =>
  api.get(dispatch, FETCH_NATIONALITY, api.REF_URL + 'nationality', {
    404: errors.ERR_NO_Nationality,
    error: errors.ERR_FETCH_Nationality,
  });
