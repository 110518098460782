import * as api from '../api';
import * as localStorage from '../localStorage';
import { SORT_TOP_NEW } from '../../utils/constants';

export const UPDATE_AVYOOER = 'UPDATE_AVYOOER';
export const AVYOOER_MORE_INFO = 'AVYOOER_MORE_INFO';
export const GET_AVYOOER = 'GET_AVYOOER';
export const FETCH_AVYOOER_VYOOS = 'FETCH_AVYOOER_VYOOS';
export const FETCH_AVYOOER_FAVORITES_VYOOS = 'FETCH_AVYOOER_FAVORITES';
export const FETCH_AVYOOER_VOTES_VYOOS = 'FETCH_AVYOOER_VOTES';
export const PUT_AVYOOER = 'PUT_AVYOOER';
export const CHOOSE_VYOOSAVYOOER_SORT = 'CHOOSE_VYOOSAVYOOER_SORT';
export const CHOOSE_VYOOSAVYOOER_REGION = 'CHOOSE_VYOOSAVYOOER_REGION';
export const UPDATE_AVYOOER_PASSWORD = 'UPDATE_AVYOOER_PASSWORD';
export const AVYOOER_CATEGORIES = 'AVYOOER_CATEGORIES';

export const errors = {
  ERR_PUT_AVYOOER_MORE_INFO: 'errors.AvyooerMoreInfoError',
  ERR_GET_AVYOOER: 'errors.getAvyooerError',
  ERR_FETCH_AVYOOER_VYOOS: 'errors.fetchAvyooerVyoosError',
  ERR_NO_AVYOOER_VYOOS: 'errors.noAvyooerVyoos',
  ERR_FETCH_AVYOOER_FAVORITES_VYOOS: 'errors.fetchAvyooerFavorites',
  ERR_NO_AVYOOER_FAVORITES_VYOOS: 'errors.noAvyooerFavorites',
  ERR_FETCH_AVYOOER_VOTES_VYOOS: 'errors.fetchAvyooerVotes',
  ERR_NO_AVYOOER_VOTES_VYOOS: 'errors.noAvyooerVotes',
  ERR_PUT_AVYOOER: 'errors.updateAvyooerError',
  ERR_UPDATE_AVYOOER_PASSWORD: 'errors.updateAvyooerPasswordError',
  ERR_AVYOOER_CATEGORIES: 'errors.AvyooerCategoriesError',
};

export const putAvyooerMoreInfo = (avyooer) => (dispatch) =>
  api.put(dispatch, AVYOOER_MORE_INFO, api.AVYOOER_MORE_INFO_URL, avyooer, {
    error: errors.ERR_PUT_AVYOOER_MORE_INFO,
  });

export const UpdateAvyooerPassword = (oldPassword, newPassword) => (
  dispatch
) => {
  api.put(
    dispatch,
    UPDATE_AVYOOER_PASSWORD,
    api.USER_URL + '/password',
    { oldPassword, newPassword },
    {
      error: errors.ERR_UPDATE_AVYOOER_PASSWORD,
    }
  );
};

export const putAvyooer = (avyooer) => (dispatch) =>
  api.put(dispatch, PUT_AVYOOER, api.AVYOOER_URL, avyooer, {
    error: errors.ERR_PUT_AVYOOER,
  });

export const getLoggedInAvyooer = () => (dispatch) =>
  api.get(dispatch, GET_AVYOOER, api.AVYOOER_URL + localStorage.avyooerId(), {
    error: errors.ERR_GET_AVYOOER,
  });

export const fetchAvyooerVyoos = (sort, region, regionDetail, last) => (
  dispatch
) =>
  api.get(
    dispatch,
    FETCH_AVYOOER_VYOOS,
    buildUrl(
      sort,
      region,
      regionDetail,
      last,
      api.AVYOOER_ACTIVITIES_VYOOS_URL
    ),
    {
      404: errors.ERR_NO_AVYOOER_VYOOS,
      error: errors.ERR_FETCH_AVYOOER_VYOOS,
    }
  );

export const fetchAvyooerFavoritesVyoos = (
  sort,
  region,
  regionDetail,
  last
) => (dispatch) =>
  api.get(
    dispatch,
    FETCH_AVYOOER_FAVORITES_VYOOS,
    buildUrl(
      sort,
      region,
      regionDetail,
      last,
      api.AVYOOER_ACTIVITIES_FAVORITES_URL
    ),
    {
      404: errors.ERR_NO_AVYOOER_FAVORITES_VYOOS,
      error: errors.ERR_FETCH_AVYOOER_FAVORITES_VYOOS,
    }
  );

export const fetchAvyooerVotedVyoos = (sort, region, regionDetail, last) => (
  dispatch
) =>
  api.get(
    dispatch,
    FETCH_AVYOOER_VOTES_VYOOS,
    buildUrl(
      sort,
      region,
      regionDetail,
      last,
      api.AVYOOER_ACTIVITIES_VOTES_URL
    ),
    {
      404: errors.ERR_NO_AVYOOER_VOTES_VYOOS,
      error: errors.ERR_FETCH_AVYOOER_VOTES_VYOOS,
    }
  );

export const setVyoosAvyooerSort = (sort) => {
  return {
    type: CHOOSE_VYOOSAVYOOER_SORT,
    sortVyoos: sort,
  };
};

export const setVyoosAvyooerRegion = (region, regionDetail) => {
  return {
    type: CHOOSE_VYOOSAVYOOER_REGION,
    regionVyoos: region,
    regionDetailVyoos: regionDetail,
  };
};

export const postAvyooerCategories = (categories) => (dispatch) => {
  const categoryIds = categories.map((category) => category.id);
  api.post(
    dispatch,
    AVYOOER_CATEGORIES,
    api.CATEGORIES_URL,
    {
      categories: categoryIds,
    },
    {
      error: errors.ERR_AVYOOER_CATEGORIES,
    }
  );
};

const buildUrl = (sort, region, regionDetail, last, url) => {
  let queryParams = '';

  let avyooer = localStorage.avyooer();
  if (avyooer) {
    if (avyooer.categories) {
      queryParams += '&categories=' + avyooer.categories.toString();
    }
    if (avyooer.country) {
      queryParams += '&country=' + avyooer.country;
    }
    if (avyooer.nationality) {
      queryParams += '&nationality=' + avyooer.nationality;
    }
  }

  if (region) {
    queryParams += '&region=' + region;

    if (region !== 'WW') {
      queryParams += '&detail=' + regionDetail;
    }
  }

  queryParams += '&sort=' + (sort || SORT_TOP_NEW);

  return url + (last ? '&last=' + last : '') + queryParams;
};
