import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import middlewares from './middlewares';
import loadingReducer from './reducers/loadingReducer';
import errorReducer from './reducers/errorReducer';
import authReducer from './reducers/authReducer';
import refReducer from './reducers/refReducer';
import vyooReducer from './reducers/vyooReducer';
import avyooerReducer from './reducers/avyooerReducer';

const rootReducer = combineReducers({
  loading: loadingReducer,
  errors: errorReducer,
  auth: authReducer,
  ref: refReducer,
  vyoo: vyooReducer,
  avyooer: avyooerReducer,
});

const configureStore = createStore(
  rootReducer,
  applyMiddleware(thunk, ...middlewares)
);

export default configureStore;
