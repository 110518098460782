import React, { useContext, useState } from 'react';
import {
  AppBar,
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { ReactComponent as Privacy } from '../../../assets/svg/privacy.svg';
import { ReactComponent as Assignment } from '../../../assets/svg/assignment.svg';
import { ReactComponent as Info } from '../../../assets/svg/info.svg';
import { ReactComponent as Log } from '../../../assets/svg/log.svg';
import { ReactComponent as Langue } from '../../../assets/svg/logotype.svg';
import i18n from 'i18next';
import ModalLanguage from '../../languages/ModalLanguage';
import * as actions from '../../../store/actions';
import withPageUtils from '../../../utils/hoc/withPageUtils';
import { RedirectContext } from '../../../utils/redirect';
import { useDispatch } from 'react-redux';

import './Menu.css';
import palette from '../../../theme/palette';
import { ReactComponent as Person } from '../../../assets/svg/person.svg';
import { ReactComponent as Login } from '../../../assets/svg/login.svg';
import Container from '@material-ui/core/Container';
import { ReactComponent as Version } from '../../../assets/svg/avyoo-icon.svg';
import { ReactComponent as Pen } from '../../../assets/svg/pen.svg';
import * as localStorage from '../../../store/localStorage';
import { guestAvyooerId, guestAvyooerName } from '../../../store/localStorage';
import CreateVyooRestrict from '../../vyoo/CreateVyooRestrict';

// FIXME
const Menu = ({ t, avyooer, authenticated, onClose, checkLoggedIn }) => {
  const { setRedirect } = useContext(RedirectContext);
  const dispatch = useDispatch();

  const [openModalLanguage, setOpenModalLanguage] = useState(false);
  const [openCreateVyooRestrict, setOpenCreateVyooRestrict] = useState(false);

  const logOut = () => {
    dispatch(actions.logOut());
    dispatch(actions.fetchVyoos());
    setRedirect('/');
  };

  const apply = () => {
    setOpenModalLanguage(false);
    onClose();
  };
  return (
    <>
      <AppBar position="static" wrapper="span">
        <Toolbar>
          <Typography style={{ color: palette.primary.dark, flexGrow: 1 }}>
            <b> {authenticated ? avyooer?.name : t('login.login')}</b>
          </Typography>
          <IconButton
            className="menu-header-button"
            edge="end"
            onClick={() =>
              checkLoggedIn(t('login.login')) && setRedirect('setting/Profile')
            }
          >
            {authenticated ? (
              avyooer?.avatar ? (
                <Avatar src={avyooer.avatar} />
              ) : (
                <Person
                  style={{ fill: palette.primary.dark }}
                  className="menu-header-icon"
                />
              )
            ) : (
              <Typography style={{ color: '#2a547f' }}>
                <b>
                  {guestAvyooerId() && guestAvyooerName()
                    ? guestAvyooerName()
                    : t('vyoo.guest.name')}
                </b>
              </Typography>
            )}
          </IconButton>
        </Toolbar>
      </AppBar>
      {!authenticated && (
        <>
          <Container style={{ marginTop: 10, marginBottom: 25 }}>
            <Grid
              direction="column"
              justify="center"
              spacing={2}
              container
              style={{ textAlign: 'center' }}
            >
              <Grid item>
                <Typography variant="h5" color="secondary">
                  {t('account.dontHaveAccount')}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ backgroundColor: palette.primary.dark }}
                  onClick={checkLoggedIn}
                >
                  {t('account.signup.signup')} / {t('login.login')}
                </Button>
              </Grid>
            </Grid>

            <Grid
              style={{ marginTop: 14, textAlign: 'center' }}
              direction="column"
              justify="center"
              spacing={1}
              container
            >
              <Typography variant="h6" color="inherit">
                {t('menu.menuTitle')}
              </Typography>
            </Grid>
          </Container>
          <Divider className="divider" />
        </>
      )}
      {!authenticated && (
        <>
          <List className="menu-list">
            <ListItem
              button
              onClick={() => {
                localStorage.guestAvyooerId() &&
                localStorage.totalVyoosGuest() === 0
                  ? setOpenCreateVyooRestrict(true)
                  : setRedirect('/vyoo/new');
              }}
            >
              <ListItemAvatar>
                <Pen
                  className="menu-icon"
                  style={{ fill: palette.primary.dark }}
                />
              </ListItemAvatar>
              <ListItemText primary={t('menu.createVyoo')} />
              <ListItemText
                style={{ textAlign: 'end' }}
                secondary={
                  <Typography style={{ color: '#607d8b' }}>
                    {localStorage.totalVyoosGuest()} {t('menu.vyoos')}
                  </Typography>
                }
              />
            </ListItem>

            <ListItem
              button
              onClick={() => {
                setOpenModalLanguage(true);
              }}
            >
              <ListItemAvatar>
                <Langue
                  className="menu-icon"
                  style={{ fill: palette.primary.dark }}
                />
              </ListItemAvatar>
              <ListItemText primary={t('menu.language')} />
              <ListItemText
                style={{ textAlign: 'end' }}
                secondary={localStorage
                  .languageList()
                  .map(
                    (language) =>
                      language.code === i18n.language.substr(0, 2) &&
                      language.name
                  )}
              />
            </ListItem>
          </List>
          <Divider className="divider" />
        </>
      )}

      <List className="menu-list">
        <ListItem
          button
          onClick={() => {
            setRedirect('/privacy');
          }}
        >
          <ListItemAvatar>
            <Privacy className="menu-icon" />
          </ListItemAvatar>
          <ListItemText primary={t('menu.privacy')} />
        </ListItem>
        {authenticated && <Divider className="divider" />}
        <ListItem
          button
          onClick={() => {
            setRedirect('/usersagreements');
          }}
        >
          <ListItemAvatar>
            <Assignment className="menu-icon" />
          </ListItemAvatar>
          <ListItemText primary={t('menu.usersAgreement')} />
        </ListItem>
        {authenticated && <Divider className="divider" />}
        <ListItem
          button
          onClick={() => {
            setRedirect('/aboutus');
          }}
        >
          <ListItemAvatar>
            <Info className="menu-icon" />
          </ListItemAvatar>
          <ListItemText primary={t('menu.aboutUs')} />
        </ListItem>
        {authenticated && <Divider className="divider" />}
        {authenticated && (
          <ListItem
            button
            onClick={() => {
              logOut();
            }}
          >
            <ListItemAvatar>
              <Log className="menu-icon" />
            </ListItemAvatar>
            <ListItemText primary={t('menu.logout')} />
          </ListItem>
        )}
      </List>

      <Container style={{ position: 'absolute', bottom: 10 }}>
        <Grid justify="center" container>
          <Grid item>
            <Version className="menu-icon" />
          </Grid>
          <Grid item>
            <Typography style={{ paddingTop: '4px' }}>{'V0.1.8'}</Typography>
          </Grid>
        </Grid>
      </Container>

      <ModalLanguage
        open={openModalLanguage}
        onClose={setOpenModalLanguage}
        apply={apply}
      />
      <CreateVyooRestrict
        openCreateVyooRestrict={openCreateVyooRestrict}
        onClose={() => setOpenCreateVyooRestrict(false)}
      />
    </>
  );
};

export default withPageUtils(Menu);
