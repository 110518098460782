import { errors as commonErrors } from './common';
import { errors as authErrors } from './auth';
import { errors as refErrors } from './ref';
import { errors as vyooErrors } from './vyoo';
import { errors as avyooerErrors } from './avyooer';

export const errors = {
  ...commonErrors,
  ...authErrors,
  ...refErrors,
  ...vyooErrors,
  ...avyooerErrors,
};

export * from './common';
export * from './auth';
export * from './ref';
export * from './vyoo';
export * from './avyooer';
