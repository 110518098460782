import { v4 as uuidv4 } from 'uuid';

const get = (key, DEFAULT_VALUE = '', isObject = false) => {
  let value = localStorage.getItem(key);
  if (value) {
    try {
      value = JSON.parse(value);
    } catch (e) {
      if (isObject) {
        value = DEFAULT_VALUE;
      }
    }
  } else {
    value = DEFAULT_VALUE;
  }
  return value;
};

const put = (key, value) => {
  localStorage.setItem(key, value);
};

const remove = (key) => {
  localStorage.removeItem(key);
};

export const isDirectionRtl = () => get('direction', 'ltr') === 'rtl';

export const avyooerId = () => {
  if (avyooer()) {
    return avyooer().id;
  } else {
    return guestAvyooerId();
  }
};

export const guestAvyooerId = () => {
  let guestId = get('guestAvyooerId');
  if (!guestId) {
    guestId = uuidv4();
    putGuestAvyooerId(guestId);
  }
  return guestId;
};
export const putGuestAvyooerId = (value) => put('guestAvyooerId', value);
export const removeGuestAvyooerId = () => remove('guestAvyooerId');

export const guestAvyooerName = () => get('guestAvyooerName');
export const putGuestAvyooerName = (value) => put('guestAvyooerName', value);
export const removeGuestAvyooerName = () => remove('guestAvyooerName');

export const token = () => get('token');
export const putToken = (value) => put('token', value);
export const removeToken = () => remove('token');

export const accessToken = () => get('accessToken');
export const putAccessToken = (value) => put('accessToken', value);
export const removeAccessToken = () => remove('accessToken');

export const refreshToken = () => get('refreshToken');
export const putRefreshToken = (value) => put('refreshToken', value);
export const removeRefreshToken = () => remove('refreshToken');

export const vyooSortList = () =>
  get('vyooSortList', [
    { id: 1, code: 'DT' },
    { id: 2, code: 'ML' },
    { id: 3, code: 'MV' },
    { id: 4, code: 'MS' },
  ]);
export const vyooLocationList = () =>
  get('vyooLocationList', [
    { id: 1, code: 'WW' },
    { id: 2, code: 'CO' },
    { id: 3, code: 'RG' },
  ]);

export const languageList = () =>
  get('languageList', [
    { id: 1, code: 'ar', name: 'عربية' },
    { id: 2, code: 'fr', name: 'Francais' },
    { id: 3, code: 'en', name: 'English' },
  ]);

export const avyooer = () => get('avyooer', void 0, true);
export const putAvyooer = (value) => put('avyooer', JSON.stringify(value));
export const removeAvyooer = () => remove('avyooer');

export const confirmedVotePolicy = () => get('confirmedVotePolicy', true);
export const putConfirmedVotePolicy = (value) =>
  put('confirmedVotePolicy', value);
export const removeConfirmedVotePolicy = () => remove('confirmedVotePolicy');

export const language = () => get('language', 'en');
export const putLanguage = (value) => put('language', value);
export const removeLanguage = () => remove('language');

export const cardView = () => get('cardView', true);
export const putCardView = (value) => put('cardView', value);

export const totalVyoosGuest = () => get('totalVyoosGuest', 10);
export const puttotalVyoosGuest = (value) => put('totalVyoosGuest', value);
export const removetotalVyoosGuest = () => remove('totalVyoosGuest');

export const vyoos = () => get('vyoos', []);
export const putVyoos = (value) => put('vyoos', value);
export const removeVyoos = () => remove('vyoos');
