export default `
Bienvenue sur AVYOO, la meilleure communauté où vous pouvez poser n'importe quelle question sur n'importe quel sujet, voter pour n'importe quoi à tout moment et partager vos opinions avec vos amis et le monde entier. Les présentes Conditions générales régissent votre accès et votre utilisation de nos services AVYOO, via l'application mobile et le site Web.
<br /> <br />
Si vous continuez à utiliser la plateforme AVYOO, cela signifie que vous acceptez de vous conformer et d'être lié par les termes et conditions d'utilisation suivants, qui, avec notre politique de confidentialité, régissent la relation d'AVYOO avec vous.
<br /> <br />
<b>Utilisation de la plate-forme AVOO</b>
<br /> <br />
Éligibilité: toute personne âgée de plus de 13 ans peut utiliser la plateforme AVYOO. Cependant, pour les personnes de moins de cet âge, leurs parents ou tuteurs légaux doivent consentir à ces conditions d'utilisation et affirmer qu'ils acceptent cet accord en leur nom et assument la responsabilité de leur utilisation. Si vous acceptez ces Conditions et utilisez les Services au nom d'une entreprise, d'une organisation, d'un gouvernement ou d'une autre entité juridique, vous déclarez et garantissez que vous êtes autorisé à le faire.
<br /> <br />
Inscription: Lors de la création de votre compte sur la plateforme AVYOO, il vous sera demandé de fournir certaines informations vous concernant. Vous acceptez de nous fournir des informations exactes, y compris votre vrai nom. Nous traiterons vos informations conformément à notre politique de confidentialité. Vous devez veiller à maintenir la confidentialité de votre mot de passe.
<br /> <br />
Politique de confidentialité: Nos pratiques de confidentialité sont décrites dans notre politique de confidentialité. En utilisant la plate-forme AVYOO, vous acceptez notre politique de confidentialité, que vous soyez ou non un utilisateur enregistré.
<br /> <br />
Résiliation: vous pouvez fermer votre compte à tout moment en accédant aux paramètres du compte et en désactivant votre compte. Nous pouvons résilier ou suspendre votre compte AVYOO si vous enfreignez une politique d'AVYOO ou pour toute autre raison.
<br /> <br />
Mises à jour: Nous essayons toujours d'améliorer votre expérience sur la plateforme AVYOO. Il se peut que nous devions ajouter ou modifier des fonctionnalités et pouvons le faire sans préavis.
<br /> <br />
Commentaires: Nous apprécions vos commentaires et suggestions sur la façon d'améliorer la plate-forme AVYOO. N'hésitez pas à soumettre vos commentaires à admin@avyoo.com. En soumettant des commentaires, vous acceptez de nous accorder le droit, à notre discrétion, d'utiliser, de divulguer et d'exploiter les commentaires, en tout ou en partie, librement et sans compensation pour vous.
<br /> <br />
<b>Gérer votre contenu</b>
<br /> <br />
Votre contenu: La plateforme AVYOO vous permet de créer des sondages et d'ajouter des messages, des textes, des photos, des vidéos, des liens et des fichiers à partager avec d'autres. Tout le matériel que vous téléchargez, publiez ou affichez à d'autres via la plate-forme AVYOO sera collectivement appelé «votre contenu». Vous reconnaissez et acceptez que votre contenu puisse être consulté par le grand public.
<br /> <br />
Vos responsabilités: En publiant votre contenu sur la plateforme AVYOO, vous déclarez et nous garantissez que:
<br /> <br />
<li> 
    Vous avez les droits de propriété, ou vous avez obtenu toutes les licences ou autorisations nécessaires de toute partie nécessaire, pour utiliser votre contenu et nous accorder les droits d'utiliser votre contenu tel que prévu dans le présent contrat, et
</li>
<li>
   Votre contenu ne viole aucune propriété intellectuelle ou droits personnels d'autrui ni aucune loi ou réglementation applicable.
</li>
<br /> <br />
Ainsi, vous acceptez l'entière responsabilité d'éviter la violation de la propriété intellectuelle ou des droits personnels d'autrui ou la violation des lois et règlements en relation avec votre contenu. Vous êtes également responsable de vous assurer que votre contenu ne viole pas les politiques d'AVYOO, ni aucune loi ou réglementation applicable.
<br /> <br />
<b>Gérer notre contenu</b>
<br /> <br />
La plateforme AVYOO contient des données et du matériel qui nous appartiennent ou nous sont concédés sous licence. Ce matériel comprend, mais sans s'y limiter, la conception, la mise en page, l'aspect, l'apparence et les graphiques. La reproduction est interdite sauf en vertu de l'avis de droit d'auteur, qui fait partie de ces conditions d'utilisation.
<br /> <br />
Toutes les marques de commerce reproduites dans AVYOO qui ne sont pas la propriété ou sous licence de l'opérateur sont reconnues sur notre plateforme. Une utilisation non autorisée peut donner lieu à une demande de dommages-intérêts et / ou constituer une infraction pénale.
<br /> <br />
AVYOO peut également inclure de nouveaux liens vers d'autres sites Web, de temps à autre. Ces liens sont fournis pour votre commodité afin de fournir de plus amples informations. Nous n'avons aucun contrôle sur la nature, le contenu et la disponibilité de ces sites. L'inclusion de liens n'implique pas nécessairement une recommandation ou n'approuve pas les opinions exprimées en leur sein.
<br /> <br />
Tous les efforts sont faits pour améliorer les performances et les fonctionnalités d'AVYOO. Cependant, AVYOO n'assume aucune responsabilité et ne sera pas responsable de l'indisponibilité temporaire de la plateforme en raison de problèmes techniques indépendants de notre volonté.
<br /> <br />
Cet accord est effectif à partir du 11 mai 2020.

`;