import React, { useState, useContext, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Typography,
  Avatar,
} from '@material-ui/core';
import { ReactComponent as Person } from '../../assets/svg/person.svg';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import HomeIcon from '@material-ui/icons/Home';
import Logo from '../../assets/svg/logo.svg';
import { RedirectContext } from '../../utils/redirect';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffectWithoutFirstRun } from '../../utils/hook';
import Menu from './components/Menu';
import palette from '../../theme/palette';
import * as actions from '../../store/actions';
import { useDispatch } from 'react-redux';

import './AppHeader.css';

const AppHeader = ({ position = 'sticky', title, back, share }) => {
  const { setRedirect } = useContext(RedirectContext);
  const history = useHistory();
  const dispatch = useDispatch();

  const [leftDrawerOpen, setLeftDrawerOpen] = useState(false);
  const authState = useSelector((state) => state.auth);
  const avyooerState = useSelector((state) => state.avyooer);

  useEffect(() => {
    if (authState.authenticated && !avyooerState.avyooer) {
      dispatch(actions.getLoggedInAvyooer());
    }
  }, [authState.authenticated]);

  useEffectWithoutFirstRun(() => {
    setLeftDrawerOpen(false);
  }, [authState.authenticated]);

  return (
    <>
      <AppBar position={position} style={{ direction: back && 'ltr' }}>
        <Toolbar>
          <IconButton
            edge={!authState.authenticated && 'start'}
            className={authState.authenticated ? 'app-header-avatar' : ''}
            onClick={() =>
              back
                ? history.goBack()
                : share
                ? setRedirect('/')
                : setLeftDrawerOpen(true)
            }
          >
            {back ? (
              <ChevronLeftIcon style={{ marginLeft: '-9px' }} />
            ) : authState.authenticated && !share ? (
              avyooerState.avyooer ? (
                <Avatar src={avyooerState.avyooer?.avatar} />
              ) : (
                <Person
                  className="menu-header-icon"
                  style={{ fill: palette.primary.dark }}
                />
              )
            ) : share ? (
              <HomeIcon />
            ) : (
              <MenuIcon />
            )}
          </IconButton>
          {title ? (
            <Typography variant="h4" className="app-header-title">
              {title}
            </Typography>
          ) : (
            <img src={Logo} alt="" className="app-header-logo" />
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        open={leftDrawerOpen}
        onClose={() => setLeftDrawerOpen(false)}
        PaperProps={{ className: 'app-header-left-drawer' }}
      >
        <Menu
          avyooer={avyooerState.avyooer}
          authenticated={authState.authenticated}
          onClose={() => setLeftDrawerOpen(false)}
        />
      </Drawer>
    </>
  );
};

export default AppHeader;
